/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import Loader from '../../../Components/Guest/loader/Loader';
import axiosInstance from '../../../utlilites/axios';

const AddConsultancy = () => {
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    // Multiple Input Code Start
    const [timeArray, setTimeArray] = useState(['']);
    const handlePlusClick = () => {
        setTimeArray([...timeArray, '']); // Add a new empty input
    };
    const handleMinusClick = () => {
        if (timeArray.length > 1) {
            const newTimeArray = [...timeArray];
            newTimeArray.pop(); // Remove the last input
            setTimeArray(newTimeArray);
        }
    };
    const handleChange = (value, index) => {
        const newTimeArray = [...timeArray];
        newTimeArray[index] = value;
        setTimeArray(newTimeArray);
    };
    // Multiple Input Code End
    
    
    
    const initialSchema = Yup.object().shape({
        title: Yup.string().required("title is Required"),
        description: Yup.string().required("description is Required"),
        consultancy_date: Yup.string().required("date is Required"),
    });

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            consultancy_date: "",
        },

        validationSchema: initialSchema,
        onSubmit: (values) => {
            setLoader(true);
            try {
                const AddSubadminData = new FormData();
                AddSubadminData.append('title', values?.title);
                AddSubadminData.append('description', values?.description);
                AddSubadminData.append('consultancy_date', values?.consultancy_date);
                timeArray.map((time, i) => {
                    AddSubadminData.append(`slots[${i}]`, time);
                })
                axiosInstance.post("admin/create/consultancy", AddSubadminData)
                    .then((response) => {
                        if (response?.data?.status === true) {
                            console.log(response, "consultancy")
                            setLoader(false);
                            toast.success("Consultancy Save Successfull");
                            navigate('/admin/consultancy');
                        }
                    }).catch((errors) => {
                        setLoader(false);
                        console.error(errors);
                        toast.error('error');
                    })
            } catch (error) {
                setLoader(false);
                toast.error('error');
                console.error(error);
            }
        },
    });


    return (
        <>
            <div class="create__course sp_100">
                <div class="container">
                    <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                            <h1>Add Consultancy</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <form onSubmit={formik.handleSubmit}>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Title</label>
                                                <input type="text" placeholder="Title" name="title"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.title}
                                                />
                                            </div>
                                            {formik.errors.title && <div className="error">{formik.errors.title}</div>}
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Description</label>
                                                <input type="text" placeholder="Description" name="description"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.description}
                                                />
                                            </div>
                                            {formik.errors.description && <div className="error">{formik.errors.description}</div>}
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-12 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Date</label>
                                                <input type="date" placeholder="Date" name="consultancy_date"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.consultancy_date}
                                                />
                                            </div>
                                            {formik.errors.consultancy_date && <div className="error">{formik.errors.consultancy_date}</div>}
                                        </div>
                                    </div>

                                    {/* Multiple Input Code Start */}
                                    {timeArray.map((time, index) => (
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                            <div className="dashboard__form__wraper">
                                                <div key={index} className="dashboard__form__input">
                                                    <label htmlFor="#">Time</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Time"
                                                        name={`time-${index}`}
                                                        onChange={(e) => handleChange(e.target.value, index)}
                                                        value={time}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div>
                                        <span type='text' className='calendar-span rounded border px-3 py-2 my-2' onClick={handlePlusClick}>+</span>
                                        {timeArray.length > 1 && <span type='text' className='calendar-span rounded border px-3 py-2 my-2' onClick={handleMinusClick}>-</span>}
                                    </div>
                                    {/* Multiple Input Code End */}
                                </div>


                                <div class="row">
                                    <div class="col-xl-12 d-flex justify-content-end align-items-center">
                                        <div class="dashboard__form__button">
                                            <button type="submit" class="default__button" >
                                                Add
                                                {
                                                    loader ? <Loader /> : null
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddConsultancy