/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import Loader from '../../../Components/Guest/loader/Loader';
import { getCoursesuccess } from '../../../Redux/Slices/Courses';
import axiosInstance from '../../../utlilites/axios';
import { useDispatch } from '../../../Redux/store';

const CompanyCourse = () => {
    const [loader, setLoader] = useState(false);
    const [Active, setActive] = useState(true);
    const [Courses, setCourses] = useState([]);
    const [rowStates, setRowStates] = useState({});
    const role = JSON.parse(localStorage.getItem('user'))
    const company_name = role?.company?.name
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const getCompany = () => {
        try {
            axiosInstance.get(`company/courses/${company_name}`).then((response) => {
                console.log(response, 'company name--->>>>')
                setCourses(response?.data?.courses)
            }).catch((error) => {
                toast.error("Some thing went wrong")
                console.log(error, "course error")
            })
        } catch (error) {
            toast.error("Some thing went wrong")
            console.log(error, 'company name')
        }
    }

    useEffect(() => {
        getCompany()
    }, [])


    const handleStatus = async (e, id) => {
        setLoader(true);
        try {
            await axiosInstance.get(`course/enable/disable/${id}`).then((response) => {
                if (response?.data?.status) {
                    setLoader(false);
                    console.log(response, 'Status--->>>>')
                    toast.success("Status Changes Successfully")
                    dispatch(getCompany())
                }
            }).catch((errors) => {
                setLoader(false);
                toast.error("Error")
                console.log(errors, 'Status errors')
            })
        } catch (error) {
            setLoader(false);
            toast.error(" Error")
            console.log(error, 'Status errors')
        }
    }
    return (
        <>
            <div class="dashboard__content__wraper">
                <div class="dashboard__section__title">
                    <h4>Course Status</h4>
                </div>
                <div class="row">
                    {Courses?.map((e) => {
                        return (
                            <>
                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="gridarea__wraper">
                                        <div class="gridarea__img">
                                            <img loading="lazy" src={e?.image} alt="grid" className='img-fluid' style={{ maxWidth: "100%", width: "100%", height: "30vh" }} />



                                        </div>
                                        <div class="gridarea__content">
                                            <div class="gridarea__list">
                                                <ul>
                                                    <li>
                                                        <i class="icofont-book-alt"></i> {e?.lesson?.length} Lesson
                                                    </li>
                                                    <li>
                                                        <i class="icofont-clock-time"></i> 2 hr 10 min
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="gridarea__heading">
                                                <h3><a href="#">{e?.course_name}</a></h3>
                                                <p><a href="#">{e?.description}</a></p>
                                            </div>
                                            {role?.is_subscribe ?
                                                <Link to={`/company/lessons/${e?.id}`} onClick={() => { dispatch(getCoursesuccess(e)) }}>
                                                    <div class="dashboard__nav__title">
                                                        <h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                                class="feather feather-book-open me-5">
                                                                <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
                                                                <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
                                                            </svg>
                                                            Lessons </h6>
                                                    </div>
                                                </Link>
                                                : null}
                                            <div class="gridarea__bottom">
                                                <a href="instructor-details.html">
                                                    <div class="gridarea__small__img">
                                                        <img loading="lazy" src="/assets/img/grid/grid_small_2.jpg" alt="grid" />
                                                        <div class="gridarea__small__content">
                                                            <h6>{e?.name}</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div className="content">
                                                    <input
                                                        type="checkbox"
                                                        id={`btn-${e?.id}`}
                                                        onChange={() => handleStatus(e, e?.id)}
                                                        checked={rowStates[e?.id] || e?.is_disable === "0" ? true : false}
                                                    />
                                                    <label htmlFor={`btn-${e?.id}`}>
                                                        <span className="track">
                                                            {rowStates[e?.id] || e?.is_disable === "0" === true ? (
                                                                <span className="course_txt">Active</span>
                                                            ) : (
                                                                <span className="course_de-txt">De-active</span>
                                                            )}
                                                        </span>
                                                        <span className="thumb">|||</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

        </>
    )
}

export default CompanyCourse