/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
// import Loader from '../../../Components/Guest/loader/Loader';
import { getMembership } from '../../../Redux/Slices/Membership';
import axiosInstance from '../../../utlilites/axios';
import { useDispatch, useSelector } from '../../../Redux/store';
import StripeCheckout from 'react-stripe-checkout';


const CompanyMembership = () => {
    const [loader, setLoader] = useState(false);
    const [rowStates, setRowStates] = useState({});
    const [MemberShip, setMemberShip] = useState([]);
    const role = JSON.parse(localStorage.getItem('user'))
    const company_name = role?.company?.name
    const getMembership = () => {
        try {
            axiosInstance.get(`company/package/${company_name}`).then((response) => {
                console.log(response, 'package name--->>>>')
                setMemberShip(response?.data?.package)
            }).catch((error) => {
                toast.error("Some thing went wrong")
                console.log(error, "package error")
            })
        } catch (error) {
            toast.error("Some thing went wrong")
            console.log(error, 'package name')
        }
    }

    useEffect(() => {
        getMembership()
    }, [])

    const onToken = (membership, stripeToken) => {
        console.log(stripeToken, "payment data")
        const data = new FormData();
        data.append("payment_platform", "Stripe")
        data.append("token", stripeToken?.id)
        data.append("course_package_id", membership?.id)
        data.append("company_id", role?.company?.id)
        data.append("amount", membership?.price)
        data.append("email", role?.email)
        axiosInstance.post('https://learnerlounge.dev-oa.xyz/api/subscription', data, {
        }).then((response) => {
            console.log(response, "stripe")
            toast.success("Package Subscribe SuccessFully");
        });
    }

    return (
        <>

            <div class="dashboard__content__wraper">
                <div class="dashboard__section__title">
                    <h4>MemberShip</h4>
                </div>


                <div className='row'>
                    {MemberShip?.map((e) => {
                        return (
                            <>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="pricingarea__content__wraper">
                                        <div className="pricingarea__heading">
                                            <div className="pricingarea__plan__type">
                                                <h6>{e?.company_name}</h6>

                                            </div>

                                            <div className="pricingarea__number">
                                                <h1><span className="price__currency">$</span>{e?.price}<span className="price__durition">/
                                                    month</span></h1>
                                            </div>
                                            <h3>{e?.name}</h3>
                                        </div>
                                        <div className="pricingarea__list">
                                            <p>{e?.description}</p>
                                        </div>
                                        <div className="pricingarea__button">
                                            <div className='row'>
                                                <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                                    {role?.is_subscribe ?

                                                        <button type="submit" className="disabled" disabled>Subscribed</button>
                                                        :
                                                        <StripeCheckout
                                                            token={(stripeToken) => onToken(e, stripeToken)}
                                                            stripeKey="pk_test_51MdqNVAOm2Y7pmXtOPM7GnEqm0icL0bkvRAKxCdVUjnRyIKkDh5HGnVexJGiDG48c9B4kLQKxIVwCCC4DyTjdP0o00FWouzEvv"
                                                        >
                                                            <button type="submit" className="default__button" >Get Started</button>
                                                        </StripeCheckout>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        <div className="pricingarea__text">
                                            {/* <p>No creadit card required</p> */}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>

            </div>






        </>
    )
}

export default CompanyMembership