/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Calendars from "../Calendar/Calendar";

const ConstancySection01 = () => {
  return (
    <>
      {/* <!-- breadcrumbarea__section__start --> */}

      <div className="breadcrumbarea">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="breadcrumb__content__wraper" data-aos="fade-up">
                <div className="breadcrumb__title">
                  <h2 className="heading">Consultancy Page</h2>
                </div>
                <div className="breadcrumb__inner">
                  <ul>
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li>Consultancy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape__icon__2">
          <img
            loading="lazy"
            className=" shape__icon__img shape__icon__img__1"
            src="assets/img/herobanner/herobanner__1.png"
            alt="photo"
          />
          <img
            loading="lazy"
            className=" shape__icon__img shape__icon__img__2"
            src="assets/img/herobanner/herobanner__2.png"
            alt="photo"
          />
          <img
            loading="lazy"
            className=" shape__icon__img shape__icon__img__3"
            src="assets/img/herobanner/herobanner__3.png"
            alt="photo"
          />
          <img
            loading="lazy"
            className=" shape__icon__img shape__icon__img__4"
            src="assets/img/herobanner/herobanner__5.png"
            alt="photo"
          />
        </div>
      </div>
      {/* <!-- breadcrumbarea__section__end--> */}

      {/* <!-- event__section__start --> */}
      <div className="eventarea sp_top_100 sp_bottom_100">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="eventarea__details__small__button" data-aos="fade-up">
                <a href="#">Mechanical</a>
              </div>
              <div className="event__details__heading" data-aos="fade-up">
                <h3>
                  Become a product manager learn the
                  <br /> Skills & job.
                </h3>
              </div>
              <div className="eventarea__details__list">
                <ul>
                  <li>
                    <div className="event__details__small__img" data-aos="fade-up">
                      <img
                        loading="lazy"
                        src="assets/img/event/event__5.png"
                        alt="event"
                      />
                      <div className="event__details__text">
                        <span>Teacher:</span>
                        <p>Elon Masksd</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="event__details__text" data-aos="fade-up">
                      <span>Last Update:</span>
                      <p>July 24, 2023</p>
                    </div>
                  </li>
                  <li>
                    <div className="event__details__text" data-aos="fade-up">
                      <span>Location:</span>
                      <p>684 West College St. Sun City, USA</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="event__details__inner">
                <div className="event__details__img__2" data-aos="fade-up">
                  <img
                    loading="lazy"
                    src="assets/img/event/event__6.png"
                    alt="phto"
                  />
                </div>
                <div className="event__details__content" data-aos="fade-up">
                  <h4>Description</h4>
                  <p>
                    He legged it up the kyver have it mush super me old mucker
                    cheeky naff that are you taking the piss, blow off ruddy the
                    pub bite your arm off the wireless boot cor blimey guvnor
                    happy days bender what a load of rubbish, say cheers pardon
                    me horse play spiffing Why car boot gosh bubble and squeak.
                    Cheers Richard bugger show off show Que pick your nose and
                    blow off get stuffed mate chancer in my flat loo, bevvy
                    amongst hunky-dory bender bubb Matie and squeak me old
                    mucker vagabond, barmy spend a penny chimney pot young
                    delinquent bum bag the nancy arm knees chap, gosh nice one
                    knees up the wireless Charles such a fibber. Mush barmy
                    bleeding Jeffrey pardon nose barney grub loo cup of tea
                    bubble and squeak bugger all mate say, I bloke matie boy
                    tickety-boo give us the duff bugger lurgy wind up I don't
                    want no agro.
                  </p>
                </div>

                <div className="event__details__list__2" data-aos="fade-up">
                  <h4>This event will allow participants to:</h4>
                  <ul>
                    <li>
                      <i className="icofont-check"></i>
                      <p> Business's managers, leaders</p>
                    </li>
                    <li>
                      <i className="icofont-check"></i>
                      <p>
                        {" "}
                        Downloadable lectures, code and design assets for all
                        projects
                      </p>
                    </li>
                    <li>
                      <i className="icofont-check"></i>
                      <p>
                        {" "}
                        Anyone who is finding a chance to get the promotion
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="event__sidebar__wraper mt-4" data-aos="fade-up">
              <Calendars />
              </div>

              <div className="event__sidebar__wraper" data-aos="fade-up">
                <div className="event__details__sidebar__heading">
                  <h6>Sopnsored</h6>
                </div>
                <div className="event__details__img__2">
                  <img
                    loading="lazy"
                    src="assets/img/brand/brand_2.png"
                    alt="photo"
                  />
                </div>
                <div className="event__thomas">Thomas R. Toe</div>
                <div className="event__details__date">
                  <p>
                    {" "}
                    <span>Email:</span>Mirnsdomoham@gmail.com
                  </p>
                </div>

                <div className="event__details__share">
                  <span>Share:</span>
                  <div className="event__details__share__list">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="icofont-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a className="sky__color" href="#">
                          <i className="icofont-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a className="deep__red" href="#">
                          <i className="icofont-youtube-play"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- event__section__end --> */}
    </>
  );
};

export default ConstancySection01;
