import React from "react";
import HeroSection02 from "../../../Components/Guest/HeroSection02";
import HeroSection04 from "../../../Components/Guest/HeroSection04";
import HeroSection05 from "../../../Components/Guest/HeroSection05";

const GuestHome = () => {
  return (
    <>
      <HeroSection02 />
      <HeroSection04 />
      <HeroSection05 />
    </>
  );
};

export default GuestHome;
