/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useMemo } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from '../../../Redux/store';
import { getStaff, getStaffsuccess } from '../../../Redux/Slices/Staff';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Loader from '../../../Components/Guest/loader/Loader';
import axiosInstance from '../../../utlilites/axios';




const Staff = () => {
    const [loader, setLoader] = useState(false);
    const [Active, setActive] = useState(true);
    const [rowStates, setRowStates] = useState({});
    const { staff } = useSelector((state) => state.staff)
    const navigate = useNavigate()
    const role = JSON.parse(localStorage.getItem('user'))

    console.log(staff, "subadmins")
    const tableData = staff || [];


    const dispatch = useDispatch();


    const getStaff = () => {
        try {
            axiosInstance.get(`all/staff/${role?.company?.id}`).then((response) => {
                console.log(response, 'Subadmin--->>>>')
                dispatch(getStaffsuccess(response?.data?.staff));
            })
        } catch (error) {
            console.log(error)
        }
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id', //access nested data with dot notation
                header: 'S.No',
                size: 50,
            },
            {
                accessorKey: 'fname', //access nested data with dot notation
                header: 'First Name',
                size: 100,
            },
            {
                accessorKey: 'lname',
                header: 'Last Name',
                size: 100,
            },
            {
                accessorKey: 'email', //normal accessorKey
                header: 'Email',
                size: 200,
            },
            {
                accessorKey: 'is_disable', //normal accessorKey
                header: 'is_disable',
                Cell: ({ row }) => (
                    <div className="content">
                        <input
                            type="checkbox"
                            id={`btn-${row.original.id}`}
                            onChange={(e) => handleStatus(e, row.original.id)}
                            checked={rowStates[row.original.id] || row.original?.is_disable === "0" ? true : false}
                        />
                        <label htmlFor={`btn-${row.original.id}`}>
                            <span className="track">
                                {rowStates[row.original.id] || row.original?.is_disable === "0" === true ? (
                                    <span className="txt">Active</span>
                                ) : (
                                    <span className="de-txt">De-active</span>
                                )}
                            </span>
                            <span className="thumb">|||</span>
                        </label>
                    </div>
                ),
                size: 100,
            },

        ],
        [],
    );

    useEffect(() => {
        getStaff()
    }, [])

    const handleDelete = async (id) => {
        setLoader(true);
        try {
            await axiosInstance.get(`delete/staff/${id}`).then((response) => {
                if (response?.data?.status) {
                    setLoader(false);
                    console.log(response, 'delete--->>>>')
                    toast.success("Delete Successfully")
                    dispatch(getStaff())
                }
            }).catch((errors) => {
                setLoader(false);
                toast.error("Error")
                console.log(errors, 'delete errors')
            })
        } catch (error) {
            setLoader(false);
            toast.error("Error")
            console.log(error, 'delete errors')
        }
    }

    const handleStatus = async (e, id) => {
        setLoader(true);
        try {
            await axiosInstance.get(`staff/enable/disable/${id}`).then((response) => {
                if (response?.data?.status) {
                    setLoader(false);
                    console.log(response, 'Status--->>>>')
                    toast.success("Status Changes Successfully")
                    dispatch(getStaff())
                }
            }).catch((errors) => {
                setLoader(false);
                toast.error("Error")
                console.log(errors, 'Status errors')
            })
        } catch (error) {
            setLoader(false);
            toast.error(" Error")
            console.log(error, 'Status errors')
        }
    }

    return (
        <>
            <div class="container-fluid full__width__padding">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="dashboardarea__wraper">
                            <div class="dashboardarea__img">
                                <div class="dashboardarea__inner admin__dashboard__inner">
                                    <div class="dashboardarea__left">
                                        <div class="dashboardarea__left__img">
                                            <img loading="lazy" src="/assets/img/dashbord/dashbord__2.jpg" alt="" />
                                        </div>
                                        <div class="dashboardarea__left__content">
                                            <h5>Hello</h5>
                                            <h4>Michle Obema</h4>
                                        </div>
                                    </div>
                                    <div class="dashboardarea__star">
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                                        <span>4.0 (120 Reviews)</span>
                                    </div>
                                    <div class="dashboardarea__right">
                                        <div class="dashboardarea__right__button">
                                            <Link class="default__button" to="/company/addstaff">Add New Employee
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dashboard__content__wraper">
                <div class="dashboard__section__title">
                    <h4>Staff</h4>
                </div>
                <div class="row">


                    <MaterialReactTable
                        columns={columns}
                        data={tableData}
                        enableRowActions
                        renderRowActions={({ row }) => (

                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "nowrap",
                                        gap: "10px",
                                        justifyContent: "space-between",
                                        alignItems: "flex-center",
                                        flexDirection: "row",
                                        padding: '10px'
                                    }}
                                >
                                    {/* <button className='default__button'> */}
                                    <FaEdit fontSize={"20px"} onClick={() => navigate(`/company/editstaff/${row.original.id}`)} />

                                    {
                                        loader ? <Loader /> :

                                            <MdDelete fontSize={"20px"} onClick={() => handleDelete(row.original.id)} />
                                    }
                                    {/* </button> */}

                                </div>
                            </>
                        )}
                        positionActionsColumn='last'
                    />

                </div>
            </div >
        </>
    )
}

export default Staff