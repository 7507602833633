import React from 'react'
import GuestEditProfile from '../../../../Components/Guest/EditProfile/GuestEditProfile'

const GuestProfile = () => {
  return (
    <>
      <GuestEditProfile />
    </>
  )
}

export default GuestProfile
