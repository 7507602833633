/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Footer = () => {
  return (
    <>
      {/* <!-- footer__section__start --> */}
      <div className="footerarea">
        <div className="container">
          <div className="footerarea__wrapper footerarea__wrapper__2">
            <div className="row">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                data-aos="fade-up"
              >
                <div className="footerarea__inner footerarea__about__us">
                  <img src="assets/img/thelearnerlounge-01.png" alt="" width={220} height={140} />
                  <div className="footerarea__heading mb-2 px-4">
                    <h3>About the company</h3>
                  </div>
                  <div className="footerarea__content px-4">
                    <p>
                      Learn To Love Growth <br /> And Change And You Will <br /> Be A Success.
                      Microsoft <br /> Patch .
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-2 col-lg-2 col-md-6 col-sm-6"
                data-aos="fade-up"
              >
                <div className="footerarea__inner">
                  <div className="footerarea__heading">
                    <h3>Products</h3>
                  </div>
                  <div className="footerarea__list">
                    <ul>
                      <li>
                        <a href="#">CSR Activities</a>
                      </li>
                      <li>
                        <a href="#">Green Banking</a>
                      </li>
                      <li>
                        <a href="#">News</a>
                      </li>
                      <li>
                        <a href="#">Ongoing Campgain</a>
                      </li>
                      <li>
                        <a href="#">Updates</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6"
                data-aos="fade-up"
              >
                <div className="footerarea__inner footerarea__padding__left">
                  <div className="footerarea__heading">
                    <h3>Get Started</h3>
                  </div>
                  <div className="footerarea__list">
                    <ul>
                      <li>
                        <a href="#">Career</a>
                      </li>
                      <li>
                        <a href="#">Contact Us</a>
                      </li>
                      <li>
                        <a href="#">Government Securities</a>
                      </li>
                      <li>
                        <a href="#">Examples</a>
                      </li>
                      <li>
                        <a href="#">NIS</a>
                      </li>
                      <li className="d-flex flex-row justify-content-between w-50 cursor-pointer">
                       <img src="assets/img/insta.png" alt="" />
                       <img src="assets/img/face.png" alt="" />
                       <img src="assets/img/bird.png" alt="" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-12"
                data-aos="fade-up"
              >
                <div className="footerarea__right__wraper footerarea__inner">
                  <div className="footerarea__heading">
                    <h3>About</h3>
                  </div>
                  <div className="footerarea__list">
                    <ul>
                      <li>
                        <a href="#">IPDC at a Glance</a>
                      </li>
                      <li>
                        <a href="#">Mission, VIsion & Values</a>
                      </li>
                      <li>
                        <a href="#">Corporate Governanace</a>
                      </li>
                      <li>
                        <a href="#">Shareholders</a>
                      </li>
                      <li>
                        <a href="#">Investor Relations</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            <div className="text-center footers pt-4 pb-2">
                  <p>
                  copyright @ All Right Reserved 
                  </p>
          </div>
      {/* <!-- footer__section__end --> */}
    </>
  );
};

export default Footer;
