import React from 'react'
import ContactSection from '../../../../Components/Guest/Contact/ContactSection'

const Contact = () => {
  return (
    <>
      <ContactSection />
    </>
  )
}

export default Contact
