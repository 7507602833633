/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useRef, useState } from 'react'
import { useFormik } from 'formik';
import { useNavigate,useParams } from 'react-router-dom'
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import axiosInstance from '../../../../utlilites/axios';
import { TiDelete } from "react-icons/ti";
import { useDropzone } from 'react-dropzone';
import { FaLastfmSquare } from 'react-icons/fa';
import Loader from '../../../../Components/Guest/loader/Loader';


const AddLessons = () => {
    const [loader, setLoader] = useState(false);
    const [SelectedDocuments, setSelectedDocuments] = useState([]);
    const navigate = useNavigate()
    const {id} = useParams();

    const onDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles, "acceptedFiles")
        setSelectedDocuments(acceptedFiles[0]);
    }, []);
    console.log(SelectedDocuments, "SelectedDocuments")
    const handleRemoveDocument = () => {
        setSelectedDocuments(null);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.video,.pdf, .doc, .docx,.jpeg,.png,.gif,.xls,.xlsx,.svg,.jpg,.ppt,.pptx',
    });

    const getFileExtension = (filename) => {
        return filename?.split('.').pop();
    };

    const getFileIcon = (fileExtension) => {
        // Define a mapping of file extensions to icons
        const fileIcons = {
            pdf: './assets/img/pdf.jpg',
            doc: './assets/img/word.jpg',
            docx: './assets/img/word.jpg',
            xls: '/assets/img/excel.jpg',
            xlsx: '/assets/img/excel.jpg',
            ppt: './assets/img/powerpoint.jpg',
            pptx: './assets/img/powerpoint.jpg',
            // Add more mappings as needed
        };

        return fileIcons[fileExtension] || '/default-icon.png'; // Default icon if not found
    };

    const AddLessonSchema = Yup.object().shape({
        lesson_title: Yup.string().required('Required'),
        lesson_desc: Yup.string().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            lesson_title: '',
            lesson_desc: '',
        },
        validationSchema: AddLessonSchema,
        onSubmit: (values) => {
            setLoader(true)
            console.log(values, "formErrors");
            try {
                const AddLessonData = new FormData();
                AddLessonData.append('file', SelectedDocuments);
                AddLessonData.append('lesson_name', values.lesson_title);
                AddLessonData.append('description', values.lesson_desc);
                AddLessonData.append('course_id', id);
                axiosInstance.post("create/lesson", AddLessonData)
                    .then((response) => {
                        if (response?.data?.status === true) {
                            setLoader(false)
                            console.log(response, "AddCompanyData")
                            toast.success("Add Lesson SuccessFully");
                            navigate('admin/comapny')
                        }
                    }).catch((errors) => {
                        setLoader(false)
                        console.error(errors);
                        toast.error('error');
                    })

            } catch (error) {
                setLoader(false)
                toast.error('error');
                console.error(error);
            }
        },
    });



    return (
        <>
            <div class="create__course sp_100">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-12 col-12">
                            <form onSubmit={formik.handleSubmit}>
                                <div class="row">
                                    {/* <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="dashboard__select__heading">
                                            <span>Courses</span>
                                        </div>
                                        <div class="dashboard__selector">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>All</option>
                                                <option value="1">Web Design</option>
                                                <option value="2">Graphic</option>
                                                <option value="3">English</option>
                                                <option value="4">Spoken English</option>
                                                <option value="5">Art Painting</option>
                                                <option value="6">App Development</option>
                                                <option value="7">Web Application</option>
                                                <option value="7">Php Development</option>
                                            </select>
                                        </div>
                                    </div> */}

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Lesson Title</label>
                                                <input type="text" placeholder="Lesson Title" name='lesson_title' 
                                                 onChange={formik.handleChange}
                                                 value={formik.values.lesson_title}
                                                />
                                            </div>
                                            {formik.errors.lesson_title && <div className="error">{formik.errors.lesson_title}</div>}
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">About Lesson</label>
                                                <textarea name="lesson_desc" id="" cols="30"
                                                    rows="10" 
                                                    onChange={formik.handleChange}
                                                    value={formik.values.lesson_desc}
                                                    ></textarea>
                                            </div>
                                            {formik.errors.lesson_desc && <div className="error">{formik.errors.lesson_desc}</div>}
                                        </div>
                                    </div>

                                    <div class="col-xl-12 my-3">
                                        <div class="dashboard__form__wraper">
                                            <div>
                                                <label className="mb-1" htmlFor="cf-default-textarea">
                                                    Add Media 
                                                </label>
                                                <div {...getRootProps()} className="upload__image-wrapper w-100">
                                                    <input {...getInputProps()} name='media'/>
                                                    <button
                                                        type="button"
                                                        className="uplaod"
                                                        style={{
                                                            width: "100%",
                                                            border: "2px dashed",
                                                            borderColor: "#41DFF6",
                                                            padding: "50px",
                                                            backgroundColor: "#fff",
                                                        }}
                                                    >
                                                        Drag 'n' drop a file here, or click: <br />.jpg, .jpeg, .png, .gif, .svg, .pdf, .doc, .docx, .xls, .xlsx
                                                    </button>
                                                </div>
                                                {SelectedDocuments && (
                                                    <div className="pt-2">
                                                        <p>Selected Documents:</p>
                                                        <ul style={{ border: '2px dashed', borderColor: '#41DFF6', display: 'flex', flexWrap: 'wrap', paddingTop: '3px' }}>

                                                            <li className="p-2 d-flex justify-content-between image-area">
                                                                <div className="document">
                                                                    {SelectedDocuments?.type?.startsWith('video/') ? (
                                                                        <video
                                                                            controls
                                                                            src={URL.createObjectURL(SelectedDocuments)}
                                                                            style={{ width: '500px', height: '500px' }}
                                                                        >
                                                                            <source src={URL.createObjectURL(SelectedDocuments)} type="video/mp4" />
                                                                        </video>
                                                                    ) : (
                                                                        <img
                                                                            src={getFileIcon(getFileExtension(SelectedDocuments.name))}
                                                                            alt="File Icon"
                                                                            style={{ width: '60px', height: '60px' }}
                                                                        />
                                                                    )}
                                                                    <span style={{ width: '60px' }}>{SelectedDocuments.name}&nbsp;</span>
                                                                <button onClick={() => handleRemoveDocument()}
                                                                    className="default__button">
                                                                    <TiDelete size={25} color='red' /></button>
                                                                </div>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                )}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="dashboard__form__button">
                                            <button type='submit' class="default__button" >
                                                Add
                                                {
                                                    loader ? <Loader /> : null
                                                }
                                                </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col-xl-4 col-lg-4 col-md-12 col-12">
                            <div class="create__course__wraper">
                                <div class="create__course__title">
                                    <h4>Lessons Upload Tips</h4>
                                </div>
                                <div class="create__course__list">
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Set the Course Price option make it free.
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Standard size for the course thumbnail.
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Video section controls the course overview video.
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Course Builder is where you create  course.
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Add Topics in the Course Builder section to create lessons, .
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Prerequisites refers to the fundamental courses .
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Information from the Additional Data section.
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

export default AddLessons