/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../utlilites/axios";
import { setSession } from "../../utlilites/jwt";
import toast from 'react-hot-toast';
import Loader from "../../Components/Guest/loader/Loader";

const Login = () => {
  const [loader, setLoader] = useState(false);
  const loginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoader(true);
      console.log(values.errors, "formErrors");
      try {
        const loginData = new FormData();
        loginData.append("email", values?.email);
        loginData.append("password", values?.password);
        loginData.append("role", "1");
        axiosInstance.post("user/login", loginData).then((response) => {
          if (response?.data?.status === true) {
            setLoader(false);
            console.log(response, "login");
            setSession(response?.data?.token, response?.data?.user);
            toast.success("Login SuccessFully")
            if(response?.data?.user?.role_id == 1){
              window.location.href = "/admin/dashboard";
            }else{
              window.location.href = "/subadmin/dashboard";
            }
          }
        });
      } catch (error) {
        setLoader(false);
        toast.error("Error")

        console.error(error);
      }
    },
  });

  return (
    <>
      <div class="breadcrumbarea">
        <div class="loginarea">
          <div class="container d-flex justify-content-center">
            <div class="loginarea__wraper">
              <div class="login__heading">
                <h5 class="login__title">Login</h5>
                {/* <p class="login__description">Don't have an account yet? <a href="#" data-bs-toggle="modal" data-bs-target="#registerModal">Sign up for free</a></p> */}
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="login__form">
                  <label className="form__label">Username or email</label>
                  <input
                    className="common__login__input"
                    type="text"
                    placeholder="Your username or email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.errors.email && (
                    <div className="error">{formik.errors.email}</div>
                  )}
                </div>
                <div className="login__form">
                  <label className="form__label">Password</label>
                  <input
                    className="common__login__input"
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  {formik.errors.password && (
                    <div className="error">{formik.errors.password}</div>
                  )}
                </div>
                <div className="login__form d-flex justify-content-end flex-wrap gap-2">
                  <div className="text-end login__form__link">
                    <a href="#">Forgot your password?</a>
                  </div>
                </div>
                <button className="default__button w-100" type="submit">
                  Log In
                  {
                    loader ? <Loader /> : null
                  }
                </button>
              </form>

              {/* <div class="login__social__option">
                                <p>or Log-in with</p>

                                <ul class="login__social__btn">
                                    <li><a class="default__button login__button__1" href="#"><i class="icofont-facebook"></i> Gacebook</a></li>
                                    <li><a class="default__button" href="#"><i class="icofont-google-plus"></i> Google</a></li>
                                </ul>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
