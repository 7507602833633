/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";

const AboutSection01 = () => {
  return (
    <>
      {/* <!-- breadcrumbarea__section__start --> */}
      <div className="breadcrumbarea" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="breadcrumb__content__wraper">
                <div className="breadcrumb__title">
                  <h2 className="heading">About Page</h2>
                </div>
                <div className="breadcrumb__inner">
                  <ul>
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li> About Page</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape__icon__2">
          <img
            loading="lazy"
            className=" shape__icon__img shape__icon__img__1"
            src="assets/img/herobanner/herobanner__1.png"
            alt="photo"
          />
          <img
            loading="lazy"
            className=" shape__icon__img shape__icon__img__2"
            src="assets/img/herobanner/herobanner__2.png"
            alt="photo"
          />
          <img
            loading="lazy"
            className=" shape__icon__img shape__icon__img__3"
            src="assets/img/herobanner/herobanner__3.png"
            alt="photo"
          />
          <img
            loading="lazy"
            className=" shape__icon__img shape__icon__img__4"
            src="assets/img/herobanner/herobanner__5.png"
            alt="photo"
          />
        </div>
      </div>
      {/* <!-- breadcrumbarea__section__end--> */}

      {/* <!-- aboutarea__5__section__start --> */}
      <div className="aboutarea__5 sp_bottom_100 sp_top_100">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6" data-aos="fade-up">
              <div className="aboutarea__5__img" data-tilt>
                <img
                  loading="lazy"
                  src="assets/img/about/about_14.png"
                  alt="about"
                />
              </div>
            </div>

            <div className="col-xl-6 col-lg-6" data-aos="fade-up">
              <div className="aboutarea__content__wraper__5">
                <div className="section__title">
                  <div className="section__title__button">
                    <div className="default__small__button">About us</div>
                  </div>
                  <div className="section__title__heading ">
                    <h2>Transforming Business, Empowering People</h2>
                  </div>
                </div>
                <div className="about__text__5">
                  <p>
                    Welcome to THE LEARNER LOUNGE the pioneering platform
                    dedicated to reshaping the landscape of modern business
                    through transformative online training. In today's
                    fast-evolving corporate world, staying ahead means
                    constantly adapting and learning. We understand this, and
                    that's why we've created a range of comprehensive training
                    modules designed to empower individuals and companies alike.
                  </p>
                </div>

                <div className="section__title__heading ">
                  <h2>Our Mission</h2>
                </div>
                <div className="about__text__5">
                  <p>
                    At THE LEARNER LOUNGE our mission is simple yet profound: to
                    facilitate seamless business transformations by equipping
                    professionals with cutting-edge knowledge and skills. We
                    believe in the power of learning to ignite change, foster
                    growth, and drive success in any organization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- aboutarea__5__section__end --> */}

      {/* <!-- testmonialarea__section__atart --> */}
      <div className="aboutarea__5 sp_bottom_100 sp_top_100">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12" data-aos="fade-up">
              <div className="aboutarea__content__wraper__5">
                <div className="section__title">
                  <div className="section__title__heading ">
                    <h2>What We Offer</h2>
                  </div>
                </div>
                <div className="about__text__5">
                  <p>
                    Our training modules are meticulously crafted by industry
                    experts, ensuring you receive the most relevant and
                    up-to-date content. We offer a variety of packages to suit
                    different learning needs and budgets, including:
                  </p>
                  <p>
                    Individual Modules: Perfect for professionals seeking to
                    upskill in specific areas. Corporate Packages: Tailored for
                    organizations aiming to train their teams and lead
                    successful business transformations.
                  </p>
                  <p>
                    Custom Solutions: For those who require a more personalized
                    approach, we offer bespoke modules crafted to meet your
                    unique business objectives. Our Approach
                  </p>
                </div>
                <div className="about__text__5">
                  <p>
                  We blend theoretical knowledge with practical insights, delivering an engaging and interactive learning experience. Our modules are accessible online, giving you the flexibility to learn at your own pace, from anywhere in the world.
                  </p>
                </div>
                <div className="section__title">
                  <div className="section__title__heading ">
                    <h2>Why Choose Us?</h2>
                  </div>
                </div>
                <div className="about__text__5">
                  <p>
                  Expertise: Our courses are developed by seasoned professionals with real-world experience.
                  </p>
                  <p>
                  Flexibility: Learn when it suits you, with accessible online content available 24/7.
                  </p>
                  <p>
                  Practicality: Our training is not just theoretical; it's about real skills for real business challenges.
                  </p>
                  <p>
                  Support: We offer ongoing support to ensure your learning journey is smooth and beneficial.
                  </p>
                  <p>
                  Join us at THE LEARNER LOUNGE and be a part of the modern business transformation. 
                  </p>
                  <p>
                  Whether you're an individual looking to advance your career or a company seeking to equip your team with essential skills, we're here to help you succeed in today's dynamic business environment. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- testmonialarea__section__end--> */}
    </>
  );
};

export default AboutSection01;
