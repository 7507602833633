/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useSelector } from '../../../../Redux/store';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import axiosInstance from '../../../../utlilites/axios';
import toast from 'react-hot-toast';
import Loader from '../../../../Components/Guest/loader/Loader';

const Lessons = () => {
    const [loader, setLoader] = useState(false);
    const [rowStates, setRowStates] = useState({});
    const { id } = useParams();
    const { Singlecourse } = useSelector((state) => state.Course)
    console.log(Singlecourse, "subadmins")

    const navigate = useNavigate()

    const handleDelete = async (id) => {
        try {
            setLoader(true)
            await axiosInstance.get(`delete/lesson/${id}`).then((response) => {
                if (response?.data?.status) {
                    setLoader(false)
                    console.log(response, 'delete--->>>>')
                    toast.success("Delete Successfully")
                    navigate('admin/comapny')
                }
            }).catch((errors) => {
                setLoader(false)
                toast.error("Error")
                console.log(errors, 'delete errors')
            })
        } catch (error) {
            setLoader(false)
            toast.error("Error")
            console.log(error, 'delete errors')
        }
    }

    const handleStatus = async (e,id) => {
        setLoader(true);
        try {
            await axiosInstance.get(`lesson/enable/disable/${id}`).then((response) => {
                if (response?.data?.status) {
                    setLoader(false);
                    console.log(response, 'Status--->>>>')
                    toast.success("Status Changes Successfully")
                    // window.location.reload()
                    navigate('/admin/comapny')
                }
            }).catch((errors) => {
                setLoader(false);
                toast.error("Error")
                console.log(errors, 'Status errors')
            })
        } catch (error) {
            setLoader(false);
            toast.error(" Error")
            console.log(error, 'Status errors')
        }
    }

    return (
        <>
            <div class="container-fluid full__width__padding">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="dashboardarea__wraper">
                            <div class="dashboardarea__img">
                                <div class="dashboardarea__inner admin__dashboard__inner">
                                    <div class="dashboardarea__left">
                                        <div class="dashboardarea__left__img">
                                            <img loading="lazy" src="/assets/img/dashbord/dashbord__2.jpg" alt="" />
                                        </div>
                                        <div class="dashboardarea__left__content">
                                            <h5>Hello</h5>
                                            <h4>Michle Obema</h4>
                                        </div>
                                    </div>
                                    <div class="dashboardarea__star">
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                                        <span>4.0 (120 Reviews)</span>
                                    </div>
                                    <div class="dashboardarea__right">
                                        <div class="dashboardarea__right__button">
                                            <Link class="default__button" to={`/admin/addlesson/${id}`}>Create a New Lessons
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard__content__wraper">
                <div class="dashboard__section__title">
                    <h4>Lessons Status</h4>
                </div>
                <div class="row">
                    {Singlecourse?.lesson?.map((e) => {
                        return (
                            <>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="gridarea__wraper">
                                        <div class="gridarea__img">
                                            <video controls style={{ width: "500px", height: '300px' }}>
                                                <source src={e?.file} />
                                            </video>
                                            <div class="gridarea__small__button">
                                                <div class="grid__badge blue__color">
                                                    <FaEdit fontSize={"20px"} onClick={() => {
                                                        navigate(`/admin/editlesson/${e?.id}`)
                                                    }} />
                                                </div>
                                                <div class="grid__badge blue__color ms-3">
                                                    <MdDelete fontSize={"20px"} onClick={() => handleDelete(e?.id)} />

                                                </div>
                                            </div>
                                            <div class="gridarea__small__icon">
                                                <a href="#"><i class="icofont-heart-alt"></i></a>
                                            </div>

                                        </div>
                                        <div class="gridarea__content">
                                            <div class="gridarea__list">
                                                <ul>

                                                    <li>
                                                        <i class="icofont-clock-time"></i> 2 hr 10 min
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="gridarea__heading">
                                                <h3><a href="#">{e?.lesson_name}</a></h3>
                                            </div>
                                            <div class="gridarea__heading">
                                                <p><a href="#">{e?.description}</a></p>
                                            </div>
                                            <div class="gridarea__bottom">
                                                <a href="instructor-details.html">
                                                    <div class="gridarea__small__img">
                                                        <img loading="lazy" src="/assets/img/grid/grid_small_2.jpg" alt="grid" />
                                                        <div class="gridarea__small__content">
                                                            <h6>{Singlecourse?.course_name}</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div className="content">
                                                    <input
                                                        type="checkbox"
                                                        id={`btn-${e?.id}`}
                                                        onChange={() => handleStatus(e, e?.id)}
                                                        checked={rowStates[e?.id] || e?.is_disable === "0" ? true : false}
                                                    />
                                                    <label htmlFor={`btn-${e?.id}`}>
                                                        <span className="track">
                                                            {rowStates[e?.id] || e?.is_disable === "0" === true ? (
                                                                <span className="course_txt">Active</span>
                                                            ) : (
                                                                <span className="course_de-txt">De-active</span>
                                                            )}
                                                        </span>
                                                        <span className="thumb">|||</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default Lessons