import * as Yup from "yup";

export const signupSchemaGuest = Yup.object().shape({
  fname: Yup.string().required("FName is Required"),
  lname: Yup.string().required("LName is Required"),
  email: Yup.string().email("Invalid email").required("Email is Required"),
  password: Yup.string().required("Password is Required"),
  password_confirmation: Yup.string()
    .required("Password is required")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

export const initialValuesGuest = {
  fname: "",
  lname: "",
  email: "",
  password: "",
  password_confirmation: "",
};

export const signupSchemaCompany = Yup.object().shape({
  fname: Yup.string().required("FName is Required"),
  lname: Yup.string().required("LName is Required"),
  email: Yup.string().email("Invalid email").required("Email is Required"),
  password: Yup.string().required("Password is Required"),
  age: Yup.string().required("Age is Required"),
  company_name: Yup.string().required("Company_name is Required"),
  description: Yup.string().required("Description is Required"),
  address: Yup.string().required("Address is Required"),
  state: Yup.string().required("State is Required"),
  country: Yup.string().required("Country is Required"),
  city: Yup.string().required("City is Required"),
  logo: Yup.string().required("Logo is Required"),
  password_confirmation: Yup.string()
    .required("Password is required")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

export const initialValuesCompany = {
  fname: "",
  lname: "",
  email: "",
  password: "",
  age: "",
  company_name: "",
  description: "",
  address: "",
  state: "",
  country: "",
  city: "",
  logo: null,
  password_confirmation: "",
};

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is Required"),
  password: Yup.string().required("Password is Required"),
});
export const LoginInitialValues = {
  email: "",
  password: "",
};





