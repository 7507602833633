/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utlilites/axios';
import { dispatch } from '../store';

const initialState = {
  usercourses: [],
};


const slice = createSlice({
  name: 'usercourses',
  initialState,
  reducers: {
    // GET spell
    getCourses(state, action) {
      console.log(state.usercourses,"subadmins");
      state.usercourses = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getUserCourses() {
  return async () => {
    try {
      await axiosInstance.get(`client/courses`).then((response) => {
        console.log(response.data.company, 'Subadmin--->>>>')
        dispatch(slice.actions.getCourses(response?.data?.company));
      })
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------
