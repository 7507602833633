/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import axiosInstance from '../../../../utlilites/axios';
import { TiDelete } from "react-icons/ti";
import CreatableSelect from 'react-select/creatable';
import { useSelector } from '../../../../Redux/store';
import Loader from '../../../../Components/Guest/loader/Loader';


const EditCourses = () => {

    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [CompanyName, setCompanyName] = useState([]);
    const [file, setFile] = useState(null);
    const [Course_image, setCourse_image] = useState(null);
    const fileIpnput = useRef(null);
    const courseImageInput = useRef(null);
    const [Course, setCourse] = useState([]);
    const { id } = useParams()
    const { Singlecourse } = useSelector((state) => state.Course)
    console.log(Singlecourse, "subadmins")
    console.log(Course, "Course")

    const handleImageChange = (event) => {
        // Use the first selected file from the input
        setFile(event.currentTarget.files[0]);
        formik.setFieldValue('image', event.currentTarget.files[0]);
    };

    const handleCpurseImageChange = (event) => {
        // Use the first selected file from the input
        setCourse_image(event.currentTarget.files[0]);
        formik.setFieldValue('course_image', event.currentTarget.files[0]);
    };

    const handleRemoveDocument = () => {
        formik.setFieldValue('image', null);;
        formik.setFieldValue('course_image', null);
    };

    const handleButtonClick = () => {
        // Trigger the click on the file input
        if (fileIpnput.current) {
            fileIpnput.current.click();
        }
    };
    const handleCourseButtonClick = () => {
        // Trigger the click on the file input
        if (courseImageInput.current) {
            courseImageInput.current.click();
        }
    };

    const AddcompanySchema = Yup.object().shape({
        company_name: Yup.string().required('Required'),
        image: Yup.mixed(),
        course_title: Yup.string().required('Required'),
        course_Desc: Yup.string().required('Required'),
        course_image: Yup.mixed(),
    });

    const formik = useFormik({
        initialValues: {
            company_name: Singlecourse?.name || '',
            image: "",
            course_title: Singlecourse?.course_name || '',
            course_Desc: Singlecourse?.description || '',
            course_image: ""
        },
        validationSchema: AddcompanySchema,
        onSubmit: (values) => {
            console.log(values, "formErrors");
            try {
                setLoader(true);
                const AddCompanyData = new FormData();
                AddCompanyData.append('company_name', values?.company_name);
                AddCompanyData.append('logo', values.image);
                AddCompanyData.append('course_name', values.course_title);
                AddCompanyData.append('description', values.course_Desc);
                AddCompanyData.append('image', values.course_image);
                axiosInstance.post(`update/course/${id}`, AddCompanyData)
                    .then((response) => {
                        if (response?.data?.status === true) {
                            setLoader(false);
                            console.log(response, "AddCompanyData")
                            toast.success("Add Course SuccessFully");
                            navigate(-1)
                        }
                    }).catch((errors) => {
                        setLoader(false);
                        console.error(errors);
                        toast.error('error');
                    })

            } catch (error) {
                setLoader(false);
                toast.error('error');
                console.error(error);
            }
        },
    });

    useEffect(() => {
        try {
            axiosInstance.get(`get/company/name`).then((response) => {
                console.log(response, 'company name--->>>>')
                setCompanyName(response?.data?.company)

            })
        } catch (error) {
            console.log(error, 'company name')
        }
    }, [])



    return (
        <>
            <div class="create__course sp_100">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-12 col-12">
                            <form onSubmit={formik.handleSubmit}>
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Comapny Name</label>
                                                <CreatableSelect isClearable defaultValue={{ label: Singlecourse?.name, value: Singlecourse?.name }} options={CompanyName?.map((e) => ({ label: e.name, value: e.name }))}
                                                    name="company_name" onChange={(selectedOption) => {
                                                        console.log(selectedOption, "selectedOption")
                                                        // Assuming your formik.handleChange handles the changes in your form state
                                                        formik.setFieldValue("company_name", selectedOption.value);
                                                    }}
                                                />

                                            </div>
                                            {formik.errors.company_name && <div className="error">{formik.errors.company_name}</div>}
                                        </div>
                                    </div>

                                    <div class="col-xl-12 my-3">
                                        <div class="dashboard__form__wraper">
                                            <div class="">
                                                <div>
                                                    <label className="mb-1" htmlFor="cf-default-textarea">
                                                        Add Comapny Logo
                                                    </label>
                                                    <div className="upload__image-wrapper w-100">
                                                        <input
                                                            ref={fileIpnput}
                                                            type='file'
                                                            name='image'
                                                            accept=".image/jpeg,.png,.gif,.svg,.jpg"
                                                            onChange={handleImageChange}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="uplaod"
                                                            onClick={handleButtonClick}
                                                            style={{
                                                                width: "100%",
                                                                border: "2px dashed",
                                                                borderColor: "#41DFF6",
                                                                padding: "50px",
                                                                backgroundColor: "#fff",
                                                            }}
                                                        >
                                                            Drag 'n' drop a file here, or click: <br />.jpg, .jpeg, .png, .gif, .svg
                                                        </button>
                                                    </div>

                                                    <div className="pt-2">
                                                        {file &&
                                                            <>
                                                                <p>Selected file:</p>
                                                                <ul style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '3px' }}>

                                                                    <div className="document">
                                                                        {file && (
                                                                            <>
                                                                                <img
                                                                                    src={URL.createObjectURL(file)}
                                                                                    alt="File Icon"
                                                                                    style={{ width: '60px', height: '60px' }}

                                                                                />
                                                                                <span style={{ width: '60px' }}>{file?.name}&nbsp;</span>
                                                                                <button type='button' onClick={() => handleRemoveDocument()}
                                                                                    className="remove-image">
                                                                                    <TiDelete size={25} color='red' /></button>
                                                                            </>
                                                                        )}
                                                                    </div>

                                                                </ul>
                                                            </>
                                                        }
                                                    </div>
                                                    {formik.errors.image && (
                                                        <div className="error">{formik.errors.image}</div>
                                                    )}
                                                </div>




                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Course Title</label>
                                                <input type="text" placeholder="Course Title" name='course_title'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.course_title}
                                                />
                                            </div>
                                            {formik.errors.course_title && <div className="error">{formik.errors.course_title}</div>}
                                        </div>
                                    </div>


                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">About Course</label>
                                                <textarea name="course_Desc" id="" cols="30"
                                                    rows="10"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.course_Desc}
                                                ></textarea>
                                            </div>
                                            {formik.errors.course_Desc && <div className="error">{formik.errors.course_Desc}</div>}
                                        </div>
                                    </div>

                                    <div class="col-xl-12 my-3">
                                        <div class="dashboard__form__wraper">
                                            <div class="">
                                                <div>
                                                    <label className="mb-1" htmlFor="cf-default-textarea">
                                                        Add Course Image
                                                    </label>
                                                    <div className="upload__image-wrapper w-100">
                                                        <input
                                                            ref={courseImageInput}
                                                            type='file'
                                                            name='course_image'
                                                            accept=".image/jpeg,.png,.gif,.svg,.jpg"
                                                            onChange={handleCpurseImageChange}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="uplaod"
                                                            onClick={handleCourseButtonClick}
                                                            style={{
                                                                width: "100%",
                                                                border: "2px dashed",
                                                                borderColor: "#41DFF6",
                                                                padding: "50px",
                                                                backgroundColor: "#fff",
                                                            }}
                                                        >
                                                            Drag 'n' drop a file here, or click: <br />.jpg, .jpeg, .png, .gif, .svg
                                                        </button>
                                                    </div>

                                                    <div className="pt-2">
                                                        {Course_image &&
                                                            <>
                                                                <p>Selected file:</p>
                                                                <ul style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '3px' }}>

                                                                    <div className="document">
                                                                        {Course_image && (
                                                                            <>
                                                                                <img
                                                                                    src={URL.createObjectURL(Course_image)}
                                                                                    alt="File Icon"
                                                                                    style={{ width: '60px', height: '60px' }}

                                                                                />
                                                                                <span style={{ width: '60px' }}>{Course_image?.name}&nbsp;</span>
                                                                                <button type='button' onClick={() => handleRemoveDocument()}
                                                                                    className="remove-image">
                                                                                    <TiDelete size={25} color='red' /></button>
                                                                            </>
                                                                        )}
                                                                    </div>

                                                                </ul>
                                                            </>
                                                        }
                                                    </div>
                                                    {formik.errors.course_image && (
                                                        <div className="error">{formik.errors.course_image}</div>
                                                    )}
                                                </div>




                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="dashboard__form__button">
                                            <button type='submit' class="default__button" >
                                                update

                                                {
                                                    loader ? <Loader /> : null
                                                }
                                                </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>


                        <div class="col-xl-4 col-lg-4 col-md-12 col-12">
                            <div class="create__course__wraper">
                                <div class="create__course__title">
                                    <h4>Course Upload Tips</h4>
                                </div>
                                <div class="create__course__list">
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Set the Course Price option make it free.
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Standard size for the course thumbnail.
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Video section controls the course overview video.
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Course Builder is where you create  course.
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Add Topics in the Course Builder section to create lessons, .
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Prerequisites refers to the fundamental courses .
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            Information from the Additional Data section.
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditCourses