/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utlilites/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  subadmins: [],

};


const slice = createSlice({
  name: 'subadmin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET spell
    getSubadminsuccess(state, action) {
      console.log(state.subadmins,"subadmins")
      state.isLoading = false;
      state.subadmins = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getSubadmin() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      await axiosInstance.get(`all/sub/admin`).then((response) => {
        console.log(response, 'Subadmin--->>>>')
        dispatch(slice.actions.getSubadminsuccess(response?.data?.subAdmins));
      })
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------