import React from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import Footer from './Footer'

const AdminLayout = ({ children }) => {
    return (
        <>
            <Header />
            <div class="dashboard">
                <div class="container-fluid full__width__padding">
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-12">
                            <Sidebar />
                        </div>
                        <div class="col-xl-9 col-lg-9 col-md-12">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AdminLayout