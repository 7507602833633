import React from 'react'

const Profile = () => {
    const profile = JSON.parse(localStorage.getItem("user"))
    console.log(profile)
    return (
        <>
            <div class="dashboard__content__wraper">
                <div class="dashboard__section__title">
                    <h4>My Profile</h4>
                </div>
                <div class="row">

                    <div class="col-lg-4 col-md-4">
                        <div class="dashboard__form dashboard__form__margin">First Name</div>
                    </div>
                    <div class="col-lg-8 col-md-8">
                        <div class="dashboard__form dashboard__form__margin">{profile?.fname}</div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="dashboard__form dashboard__form__margin">Last Name</div>
                    </div>
                    <div class="col-lg-8 col-md-8">
                        <div class="dashboard__form dashboard__form__margin">{profile?.lname}</div>
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <div class="dashboard__form dashboard__form__margin">Email</div>
                    </div>
                    <div class="col-lg-8 col-md-8">
                        <div class="dashboard__form dashboard__form__margin">{profile?.email}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile