/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const HeroSection04 = () => {
  return (
    <>
      {/* <!-- counter__section__start --> */}
      <div className='bg-white py-5'>
      <div className='counterarea'>
      <div>
            <h1>Four reasons why you should choose us.</h1>
            </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mx-auto col-md-12 col-sm-12 col-12">
            <img src="assets/img/square.png" alt="" />
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default HeroSection04
