/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "../../../Redux/store";
// import Accordion from "react-bootstrap/Accordion";
import { Link, useParams } from "react-router-dom";

const CourseDetail = () => {
  const { id } = useParams();
  const { usercourses } = useSelector((state) => state.usercourses);
  const copiedObjects = usercourses.slice(0, 3).map((item) => ({ ...item }));
  console.log(copiedObjects);
  const findCourses = usercourses?.filter((data) => {
    // eslint-disable-next-line eqeqeq
    return data.id == id;
  });

  return (
    <>
      {/* <!-- breadcrumbarea__section__start --> */}

      <div class="breadcrumbarea">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="breadcrumb__content__wraper" data-aos="fade-up">
                <div class="breadcrumb__title">
                  <h2 class="heading">Course-Details</h2>
                </div>
                <div class="breadcrumb__inner">
                  <ul>
                    <li>
                      <a href="index-2.html">Home</a>
                    </li>
                    <li>Course-Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="shape__icon__2">
          <img
            loading="lazy"
            class=" shape__icon__img shape__icon__img__1"
            src="/assets/img/herobanner/herobanner__1.png"
            alt="photo"
          />
          <img
            loading="lazy"
            class=" shape__icon__img shape__icon__img__2"
            src="/assets/img/herobanner/herobanner__2.png"
            alt="photo"
          />
          <img
            loading="lazy"
            class=" shape__icon__img shape__icon__img__3"
            src="/assets/img/herobanner/herobanner__3.png"
            alt="photo"
          />
          <img
            loading="lazy"
            class=" shape__icon__img shape__icon__img__4"
            src="/assets/img/herobanner/herobanner__5.png"
            alt="photo"
          />
        </div>
      </div>
      {/* <!-- breadcrumbarea__section__end--> */}

      <div class="blogarea__2 sp_top_100 sp_bottom_100">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-8">
              <div
                class="blogarae__img__2 course__details__img__2"
                data-aos="fade-up"
              >
                <img
                  loading="lazy"
                  style={{ height: "60vh" }}
                  src={findCourses[0]?.image}
                  alt="blog"
                />
                <div class="registerarea__content course__details__video">
                  <div class="registerarea__video"></div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4">
              <div class="blogsidebar__content__wraper__2" data-aos="fade-up">
                <div
                  class="blogsidebar__content__inner__2"
                  style={{ height: "50vh" }}
                >
                  <div class="blogsidebar__img__2">
                    <img
                      loading="lazy"
                      src={findCourses[0]?.logo}
                      alt="blog"
                      style={{
                        width: "200px",
                        height: "200px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div class="blogsidebar__name__2">
                    <h5>
                      <a href="#">{findCourses[0]?.name}</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12 col-lg-12">
              <div class="blog__details__content__wraper">
                <div class="course__details__heading" data-aos="fade-up">
                  <h3>{findCourses[0]?.course_name}</h3>
                </div>
                <div class="course__details__paragraph" data-aos="fade-up">
                  <p>{findCourses[0]?.description}</p>
                </div>

                {/* <h4 class="sidebar__title" data-aos="fade-up">
                  Course Lesson
                </h4>
                <div class="course__details__wraper" data-aos="fade-up">
                  {findCourses[0]?.lesson?.map((data) => {
                    return (
                        <Accordion defaultActiveKey={null} className="w-100 my-2">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            {data.lesson_name}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="embed-responsive my-2 d-flex justify-content-center embed-responsive-16by9">
                              <iframe
                                className="embed-responsive-item"
                                src={data.file}
                                allowFullScreen
                              ></iframe>
                            </div>
                            {data.description}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      
                    );
                  })}
                </div> */}
              </div>

              <div class="online__course__wrap">
                <div class="instructor__heading__2" data-aos="fade-up">
                  <h3 className="mb-4">Company More Courses</h3>
                </div>
                <div
                  class="row instructor__slider__active row__custom__class"
                  data-aos="fade-up"
                >
                 {copiedObjects?.map((data) => {
              return (
                <>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 grid-item filter4">
                <Link to={`/coursedetail/${data.id}`}>
                    <div className="gridarea__wraper">
                      <div className="gridarea__img">
                        <a href="#">
                          <img
                            loading="lazy"
                            src={data.image}
                            alt="grid"
                            width={"200px"}
                            height={"200px"}
                            className="object-fit-contain"
                          />
                        </a>
                      </div>
                      <div className="gridarea__content">
                        <div className="gridarea__list">
                          <ul>
                            <li>
                              <i className="icofont-book-alt"></i>{" "}
                              {data.lesson.length} Lesson
                            </li>
                          </ul>
                        </div>
                        <div className="gridarea__heading">
                          <h3>
                            <a href="#">{data.course_name}</a>
                          </h3>
                        </div>
                        <div className="gridarea__bottom">
                          <a href="#">
                            <div className="gridarea__small__img">
                              <img loading="lazy" src={data.logo} alt="grid" />
                              <div className="gridarea__small__content">
                                <h6>{data.name}</h6>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                </Link>
                  </div>
                </>
              );
            })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetail;
