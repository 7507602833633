/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from '../../../Redux/store';

const StaffLessons = () => {
    const { id } = useParams();
    const { Singlecourse } = useSelector((state) => state.Course)
    console.log(Singlecourse, "subadmins")

    return (
        <>
            
            <div class="dashboard__content__wraper">
                <div class="dashboard__section__title">
                    <h4>Lessons Status</h4>
                </div>
                <div class="row">
                    {Singlecourse?.lesson?.map((e) => {
                        return (
                            <>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="gridarea__wraper">
                                        <div class="gridarea__img">
                                            <video controls style={{ width: "500px", height: '300px' }}>
                                                <source src={e?.file} />
                                            </video>
                                           
                                            <div class="gridarea__small__icon">
                                                <a href="#"><i class="icofont-heart-alt"></i></a>
                                            </div>

                                        </div>
                                        <div class="gridarea__content">
                                            <div class="gridarea__list">
                                                <ul>

                                                    <li>
                                                        <i class="icofont-clock-time"></i> 2 hr 10 min
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="gridarea__heading">
                                                <h3><a href="#">{e?.lesson_name}</a></h3>
                                            </div>
                                            <div class="gridarea__heading">
                                                <p><a href="#">{e?.description}</a></p>
                                            </div>
                                            <div class="gridarea__bottom">
                                                <a href="instructor-details.html">
                                                    <div class="gridarea__small__img">
                                                        <img loading="lazy" src="/assets/img/grid/grid_small_2.jpg" alt="grid" />
                                                        <div class="gridarea__small__content">
                                                            <h6>{Singlecourse?.course_name}</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default StaffLessons