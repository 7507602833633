/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Loader from "../loader/Loader";
import axios from "axios";
import { Base_Api } from "../../../config";
import { useNavigate } from "react-router-dom";

const GuestEditProfile = () => {
  const storedData = localStorage.getItem("userLoginGuest");
  const userLoginData = JSON.parse(storedData);
  console.log(userLoginData);
  const token = localStorage.getItem('userToken');
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const initialState = {
    fname: userLoginData?.user?.fname,
    lname: userLoginData?.user?.lname,
    email: userLoginData?.user?.email,
  };

  const editSchema = Yup.object().shape({
    fname: Yup.string().required("FName is Required"),
    lname: Yup.string().required("LName is Required"),
    email: Yup.string().email("Invalid email").required("Email is Required"),
  });
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: initialState,
    validationSchema: editSchema,
    onSubmit: (values) => {
      console.log(values);
      setLoader(true);
      const config ={
        headers: {  Authorization: token ? `Bearer ${token}` : undefined
        }
      } 
     
      axios.post(`${Base_Api}update/user/${userLoginData.user.id}`, values , config)
        .then((data) => {
          console.log(data);
          setLoader(true);
          if(data.data.status === true){
            setLoader(true);
            resetForm();
            toast.success("Guest Update Successfully !", {
              position: "bottom-left",
            });
            const local = JSON.stringify(data.data);
            localStorage.setItem("userLoginGuest", local);
            navigate('/');
            setLoader(false);
          }else{
            setLoader(false);
            toast.error('The email has already been taken.', {
              position: "bottom-left",
            });
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message, {
            position: "bottom-left",
          });
          setLoader(false);
        });
    },
  });
  
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="loginarea__wraper w-75 mx-auto my-5 w-sm-100 w-md-100">
            <div className="login__heading">
              <h5 className="login__title">Edit Profile Guest</h5>
            </div>{" "}
            <form onSubmit={handleSubmit}>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="login__form">
                  <label className="form__label">First Name</label>
                  <input
                    className="common__login__input"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="fname"
                    value={values.fname}
                    placeholder="First Name"
                  />
                </div>
                {errors.fname && touched.fname ? (
                  <span className="text-danger fs-6">{errors.fname}</span>
                ) : null}
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="login__form">
                  <label className="form__label">Last Name</label>
                  <input
                    className="common__login__input"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="lname"
                    value={values.lname}
                    placeholder="Last Name"
                  />
                </div>
                {errors.lname && touched.lname ? (
                  <span className="text-danger fs-6">{errors.lname}</span>
                ) : null}
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="login__form">
                  <label className="form__label">Email</label>
                  <input
                    className="common__login__input"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="email"
                    value={values.email}
                    placeholder="Email"
                  />
                </div>
                {errors.email && touched.email ? (
                  <span className="text-danger fs-6">{errors.email}</span>
                ) : null}
              </div>

              <div className="login__button">
                <button
                  className="w-100 text-center text-white border-0 py-2 rounded"
                  type="submit"
                  style={{ background: "#5F2DED" }}
                >
                  Edit Profile Guest
                  {loader ? <Loader /> : null}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestEditProfile;
