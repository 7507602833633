/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useSelector } from '../../../Redux/store';
import axiosInstance from '../../../utlilites/axios';
import toast from 'react-hot-toast';
import Loader from '../../../Components/Guest/loader/Loader';

const CompanyLessons = () => {
    const [loader, setLoader] = useState(false);
    const [rowStates, setRowStates] = useState({});
    const { id } = useParams();
    const { Singlecourse } = useSelector((state) => state.Course)
    console.log(Singlecourse, "subadmins")

    const navigate = useNavigate()
    const handleStatus = async (e,id) => {
        setLoader(true);
        try {
            await axiosInstance.get(`lesson/enable/disable/${id}`).then((response) => {
                if (response?.data?.status) {
                    setLoader(false);
                    console.log(response, 'Status--->>>>')
                    toast.success("Status Changes Successfully")
                    // window.location.reload()
                    navigate('/admin/comapny')
                }
            }).catch((errors) => {
                setLoader(false);
                toast.error("Error")
                console.log(errors, 'Status errors')
            })
        } catch (error) {
            setLoader(false);
            toast.error(" Error")
            console.log(error, 'Status errors')
        }
    }

    return (
        <>
       
            <div class="dashboard__content__wraper">
                <div class="dashboard__section__title">
                    <h4>Lessons Status</h4>
                </div>
                <div class="row">
                    {Singlecourse?.lesson?.map((e) => {
                        return (
                            <>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="gridarea__wraper">
                                        <div class="gridarea__img">
                                            <video controls style={{ width: "500px", height: '300px' }}>
                                                <source src={e?.file} />
                                            </video>
                                           
                                            <div class="gridarea__small__icon">
                                                <a href="#"><i class="icofont-heart-alt"></i></a>
                                            </div>

                                        </div>
                                        <div class="gridarea__content">
                                            <div class="gridarea__list">
                                                <ul>

                                                    <li>
                                                        <i class="icofont-clock-time"></i> 2 hr 10 min
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="gridarea__heading">
                                                <h3><a href="#">{e?.lesson_name}</a></h3>
                                            </div>
                                            <div class="gridarea__heading">
                                                <p><a href="#">{e?.description}</a></p>
                                            </div>
                                            <div class="gridarea__bottom">
                                                <a href="instructor-details.html">
                                                    <div class="gridarea__small__img">
                                                        <img loading="lazy" src="/assets/img/grid/grid_small_2.jpg" alt="grid" />
                                                        <div class="gridarea__small__content">
                                                            <h6>{Singlecourse?.course_name}</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                                {/* <div className="content">
                                                    <input
                                                        type="checkbox"
                                                        id={`btn-${e?.id}`}
                                                        onChange={() => handleStatus(e, e?.id)}
                                                        checked={rowStates[e?.id] || e?.is_disable === "0" ? true : false}
                                                    />
                                                    <label htmlFor={`btn-${e?.id}`}>
                                                        <span className="track">
                                                            {rowStates[e?.id] || e?.is_disable === "0" === true ? (
                                                                <span className="course_txt">Active</span>
                                                            ) : (
                                                                <span className="course_de-txt">De-active</span>
                                                            )}
                                                        </span>
                                                        <span className="thumb">|||</span>
                                                    </label>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default CompanyLessons