import React from 'react'
import CardsDashboard from '../../../Components/Admin/CardsDashboard'

const Dashboard = () => {
    return (
        <>
            <CardsDashboard />
        </>
    )
}

export default Dashboard