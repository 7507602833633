/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import axiosInstance from '../../../../utlilites/axios';
import { TiDelete } from "react-icons/ti";
import { useDropzone } from 'react-dropzone';
import Loader from '../../../../Components/Guest/loader/Loader';


const AddMembership = () => {
    const [loader, setLoader] = useState(false);
    const [CompanyName, setCompanyName] = useState([]);
    const navigate = useNavigate()
    const { id } = useParams();

    useEffect(() => {
        try {
            axiosInstance.get(`get/company/name`).then((response) => {
                console.log(response, 'company name--->>>>')
                setCompanyName(response?.data?.company)

            })
        } catch (error) {
            console.log(error, 'company name')
        }
    }, [])

    const AddPackageSchema = Yup.object().shape({
        company_name: Yup.string().required('Required'),
        package_title: Yup.string().required('Required'),
        Package_desc: Yup.string().required('Required'),
        Duration: Yup.string().required('Required'),
        No_Of_Months: Yup.string().required('Required'),
        price: Yup.number().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            company_name: "",
            package_title: "",
            Package_desc: "",
            Duration: "",
            No_Of_Months: "",
            price: "",
        },
        validationSchema: AddPackageSchema,
        onSubmit: (values) => {
            console.log(values, "formErrors");
            try {
                setLoader(true)
                const AddPackageData = new FormData();
                AddPackageData.append('name', values.package_title);
                AddPackageData.append('description', values.Package_desc);
                AddPackageData.append('duration', values.Duration);
                AddPackageData.append('no_of_month', values.No_Of_Months);
                AddPackageData.append('price', values.price);
                AddPackageData.append('company_name', values.company_name);
                axiosInstance.post("create/package", AddPackageData)
                    .then((response) => {
                        if (response?.data?.status === true) {
                            setLoader(false)
                            console.log(response, "AddCompanyData")
                            toast.success("Add Membership SuccessFully");
                            navigate(-1)
                        }
                    }).catch((errors) => {
                        setLoader(false)
                        console.error(errors);
                        toast.error('error');
                    })

            } catch (error) {
                setLoader(false)
                toast.error('error');
                console.error(error);
            }
        },
    });



    return (
        <>
            <div class="create__course sp_100">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <form onSubmit={formik.handleSubmit}>
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="dashboard__select__heading">
                                            <span>Select Company</span>
                                        </div>
                                        <div class="dashboard__selector">
                                            <select class="form-select" aria-label="Select Company" name='company_name'
                                                onChange={formik.handleChange}
                                                value={formik.values.company_name}
                                            >
                                                <option selected>Select Company</option>
                                                {CompanyName?.map((e) => {
                                                    return (
                                                        <>
                                                            <option value={e?.name}>{e?.name}</option>
                                                        </>
                                                    )
                                                })}

                                            </select>
                                        </div>
                                        {formik.errors.company_name && <div className="error">{formik.errors.company_name}</div>}
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Package Name</label>
                                                <input type="text" placeholder="package title" name='package_title'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.package_title}
                                                />
                                            </div>
                                            {formik.errors.package_title && <div className="error">{formik.errors.package_title}</div>}
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">About Package</label>
                                                <textarea name="Package_desc" id="" cols="30"
                                                    rows="10"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.Package_desc}
                                                ></textarea>
                                            </div>
                                            {formik.errors.Package_desc && <div className="error">{formik.errors.Package_desc}</div>}
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Duration</label>
                                                <input type="text" placeholder="Duration" name='Duration'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.Duration}
                                                />
                                            </div>
                                            {formik.errors.Duration && <div className="error">{formik.errors.Duration}</div>}
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">No Of Months</label>
                                                <input type="text" placeholder="No Of Months" name='No_Of_Months'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.No_Of_Months}
                                                />
                                            </div>
                                            {formik.errors.No_Of_Months && <div className="error">{formik.errors.No_Of_Months}</div>}
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Price</label>
                                                <input type="number" placeholder="price" name='price'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.price}
                                                />
                                            </div>
                                            {formik.errors.price && <div className="error">{formik.errors.price}</div>}
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="dashboard__form__button">
                                            <button type='submit' class="default__button" >
                                                Add
                                                {
                                                    loader ? <Loader /> : null
                                                }
                                                </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default AddMembership