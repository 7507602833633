import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import user from './Slices/user';
import SubAdmin from './Slices/SubAdmin';
import company from './Slices/Company';
import membership from './Slices/Membership';
import course from './Slices/Courses';
import userCourses from './Slices/userCourses';
import Staff from './Slices/Staff';


const rootPersistConfig = {
  key: 'root',
  storage,
  // keyPrefix: 'redux-',
  // whitelist: [],
};


const rootReducer = combineReducers({
  user:user,
  staff: Staff,
  subadmin: SubAdmin,
  Company: company,
  Membership: membership,
  Course: course,
  usercourses:userCourses,
});

export { rootPersistConfig, rootReducer };



