import React from 'react'
import ConstancySection01 from '../../../../Components/Guest/Constancy/ConstancySection01'

const Constancy = () => {
  return (
    <>
      <ConstancySection01 />
    </>
  )
}

export default Constancy


