/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import axiosInstance from '../../utlilites/axios';
import Loader from '../Guest/loader/Loader';



const PasswordEdit = () => {

    const [Profile, setProfile] = useState(true)
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()

    const ProfileSchema = Yup.object().shape({
        current_password: Yup.string()
        .required('Password is required'),
        password: Yup.string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
    confirm_password: Yup.string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    const formik = useFormik({
        initialValues: {
            current_password: "",
            password: '',
            confirm_password: '',
            
        },
        validationSchema: ProfileSchema,
        onSubmit: (values) => {
            setLoader(true);
            console.log(values.errors, "formErrors");
            try {
                const ProfileData = new FormData();
                ProfileData.append('old_password', values?.current_password);
                ProfileData.append('password', values?.password);
                ProfileData.append('password_confirmation', values?.confirm_password);
              
                axiosInstance.post("profile/update/password", ProfileData)
                    .then((response) => {
                        if (response?.data?.status === true) {
                            console.log(response, "ProfileData")
                            setLoader(false);
                            toast.success("Update Password SuccessFully");
                            navigate('/admin/profile')
                        }
                    }).catch((errors) => {
                        setLoader(false);
                        console.error(errors);
                        toast.error('error');
                    })

            } catch (error) {
                setLoader(false);
                toast.error('error');
                console.error(error);
            }
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="dashboard__form__wraper">
                            <div class="dashboard__form__input">
                                <label >Current Password</label>
                                <input type="text" placeholder="Current password" name="current_password"
                                    onChange={formik.handleChange}
                                    value={formik.values.current_password}
                                />
                            </div>
                            {formik.errors.current_password && <div className="error">{formik.errors.current_password}</div>}
                        </div>
                    </div>


                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div class="dashboard__form__wraper">
                            <div class="dashboard__form__input">
                                <label for="#">Password</label>
                                <input type="password" placeholder="Password" name="password"
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                />
                            </div>
                            {formik.errors.password && <div className="error">{formik.errors.password}</div>}
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div class="dashboard__form__wraper">
                            <div class="dashboard__form__input">
                                <label for="#">Confirm Password</label>
                                <input type="password" placeholder="Confirm Password" name="confirm_password"
                                    onChange={formik.handleChange}
                                    value={formik.values.confirm_password}
                                />
                            </div>
                            {formik.errors.confirm_password && <div className="error">{formik.errors.confirm_password}</div>}
                        </div>
                    </div>
                    <div class="col-xl-12">
                        <div class="dashboard__form__button" >
                            <button class="default__button" type="submit">
                                Update Password
                                {
                                    loader ? <Loader /> : null
                                }
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </>
    )
}

export default PasswordEdit