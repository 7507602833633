/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Signup from "./Signup";
import { LoginSchema, LoginInitialValues } from "./schema/Shcema";
import { useFormik } from "formik";
import axiosInstance from "../../../utlilites/axios";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";

const Login = () => {
  const data = useLocation();
  const navigate = useNavigate();
  const rollData = data.state.data;
  const documentLoginProject = document?.getElementById("projects__one");
  const documentLogin = document?.getElementById("login");
  if (rollData === "staff") {
    documentLoginProject?.classList?.add("active", "show");
    documentLogin?.classList?.add("active");
  }
  const [loader, setLoader] = useState(false);
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: LoginInitialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setLoader(true);
      console.log(values);
      axiosInstance
        .post("user/login", values)
        .then((data) => {
          console.log(data,'data');
          resetForm();
          toast.success("Login Successfully !", {
            position: "bottom-left",
          });
          if (data.data.user.role_id === 5) {
            localStorage.setItem("userToken", data.data.token);
            const local = JSON.stringify(data.data);
            localStorage.setItem("userLoginGuest", local);
            navigate("/");
          } else if (data.data.user.role_id === 3) {
            localStorage.setItem("accesstoken", data.data.token);
            const local = JSON.stringify(data.data.user);
            localStorage.setItem("user", local);
            window.location.href = '/company/dashboard'
          }
          else{
            localStorage.setItem("accesstoken", data.data.token);
            const local = JSON.stringify(data.data.user);
            localStorage.setItem("user", local);
            window.location.href = '/staff/dashboard'
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log(err.message);
          toast.error(err.message, {
            position: "bottom-left",
          });
          setLoader(false);
        });
    },
  });

  return (
    <>
      {/* <!-- breadcrumbarea__section__start --> */}

      <div className="breadcrumbarea">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="breadcrumb__content__wraper" data-aos="fade-up">
                <div className="breadcrumb__title">
                  <h2 className="heading">Log In</h2>
                </div>
                <div className="breadcrumb__inner">
                  <ul>
                    <Link to={"/"}>
                      <li>
                        <a href="">Home</a>
                      </li>
                    </Link>
                    <li>Log In</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape__icon__2">
          <img
            loading="lazy"
            className=" shape__icon__img shape__icon__img__1"
            src="assets/img/herobanner/herobanner__1.png"
            alt="photo"
          />
          <img
            loading="lazy"
            className=" shape__icon__img shape__icon__img__2"
            src="assets/img/herobanner/herobanner__2.png"
            alt="photo"
          />
          <img
            loading="lazy"
            className=" shape__icon__img shape__icon__img__3"
            src="assets/img/herobanner/herobanner__3.png"
            alt="photo"
          />
          <img
            loading="lazy"
            className=" shape__icon__img shape__icon__img__4"
            src="assets/img/herobanner/herobanner__5.png"
            alt="photo"
          />
        </div>
      </div>
      {/* <!-- breadcrumbarea__section__end--> */}

      {/* <!-- login__section__start --> */}
      <div className="loginarea sp_top_100 sp_bottom_100">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-md-8 offset-md-2" data-aos="fade-up">
              <ul
                className="nav  tab__button__wrap text-center"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="single__tab__link active"
                    data-bs-toggle="tab"
                    data-bs-target="#projects__one"
                    id="login"
                    type="button"
                  >
                    Login
                  </button>
                </li>
                {rollData !== "staff" && (
                  <li className="nav-item" role="presentation">
                    <button
                      className="single__tab__link"
                      data-bs-toggle="tab"
                      data-bs-target="#projects__two"
                      type="button"
                      id="signup"
                    >
                      Sign up
                    </button>
                  </li>
                )}
              </ul>
            </div>

            <div
              className="tab-content tab__content__wrapper"
              id="myTabContent"
              data-aos="fade-up"
            >
              <div
                className="tab-pane fade active show"
                id="projects__one"
                role="tabpanel"
                aria-labelledby="projects__one"
              >
                <div className="col-xl-8 col-md-8 offset-md-2">
                  <div className="loginarea__wraper w-100">
                    <div className="login__heading">
                      <h5 className="login__title">Login</h5>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="login__form">
                            <label className="form__label">Email</label>
                            <input
                              className="common__login__input"
                              type="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="email"
                              value={values.email}
                              placeholder="Email"
                            />
                          </div>
                          {errors.email && touched.email ? (
                            <span className="text-danger fs-6">
                              {errors.email}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-xl-12">
                          <div className="login__form">
                            <label className="form__label">Password</label>
                            <input
                              className="common__login__input"
                              type="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="password"
                              value={values.password}
                              placeholder="Password"
                            />
                          </div>
                          {errors.password && touched.password ? (
                            <span className="text-danger fs-6">
                              {errors.password}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="login__button">
                        <button
                          className="w-100 text-center text-white border-0 py-2 rounded"
                          type="submit"
                          style={{ background: "#E19826" }}
                        >
                          Login in
                          {loader ? <Loader /> : null}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {rollData !== "staff" && (
                <div
                  className="tab-pane fade"
                  id="projects__two"
                  role="tabpanel"
                  aria-labelledby="projects__two"
                >
                  <div className="col-xl-8 offset-md-2">
                    <div className="loginarea__wraper w-100">
                      <div className="login__heading">
                        <h5 className="login__title">Sign Up</h5>
                        <p className="login__description">
                          Already have an account?{" "}
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#registerModal"
                          >
                            Log In
                          </a>
                        </p>
                      </div>
                      <Signup />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className=" login__shape__img educationarea__shape_image">
            <img
              loading="lazy"
              className="hero__shape hero__shape__1"
              src="assets/img/education/hero_shape2.png"
              alt="Shape"
            />
            <img
              loading="lazy"
              className="hero__shape hero__shape__2"
              src="assets/img/education/hero_shape3.png"
              alt="Shape"
            />
            <img
              loading="lazy"
              className="hero__shape hero__shape__3"
              src="assets/img/education/hero_shape4.png"
              alt="Shape"
            />
            <img
              loading="lazy"
              className="hero__shape hero__shape__4"
              src="assets/img/education/hero_shape5.png"
              alt="Shape"
            />
          </div>
        </div>
      </div>
      {/* <!-- login__section__end --> */}
    </>
  );
};

export default Login;
