/* eslint-disable eqeqeq */
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminLayout from './Dashboards/Admin/AdminLayout';
import Dashboard from './Dashboards/Admin/Pages/Dashboard';
import Profile from './Dashboards/Admin/Pages/Profile';
import Course from './Dashboards/Admin/Pages/Courses/Course';
import PrifileEdit from './Dashboards/Admin/Pages/PrifileEdit';
import Login from './Auth/Login/Login';
import Membership from './Dashboards/Admin/Pages/Membership/Membership';
import SubAdmin from './Dashboards/Admin/Pages/Subadmin/SubAdmin';
import Comapny from './Dashboards/Admin/Pages/Company/Comapny';
import AddCourse from './Dashboards/Admin/Pages/Courses/AddCourse';
import Lessons from './Dashboards/Admin/Pages/Lesson/Lessons';
import AddLessons from './Dashboards/Admin/Pages/Lesson/AddLessons';
import Courses from "./Dashboards/Guest/Pages/Course/Course";
import GuestHome from "./Dashboards/Guest/Pages/GuestHome";
import LayoutGuest from "./Components/Guest/layout/LayoutGuest";
import LoginPage from "./Components/Guest/auth/Login";
import About from "./Dashboards/Guest/Pages/About/About";
import Constancy from "./Dashboards/Guest/Pages/Constancy/Constancy";
import Contact from "./Dashboards/Guest/Pages/Contact/Contact";
import CourseDetail from './Components/Guest/Course/CourseDetail';
import AddSubadmin from './Dashboards/Admin/Pages/Subadmin/AddSubadmin';
import EditSubadmin from './Dashboards/Admin/Pages/Subadmin/EditSubadmin';
import { Toaster } from 'react-hot-toast';
import EditCourses from './Dashboards/Admin/Pages/Courses/EditCourses';
import EditLesson from './Dashboards/Admin/Pages/Lesson/EditLesson';
import AddMembership from './Dashboards/Admin/Pages/Membership/AddMembership';
import EditMembership from './Dashboards/Admin/Pages/Membership/EditMembership';
import { ToastContainer } from "react-toastify"
import GuestProfile from './Dashboards/Guest/Pages/EditProfile/GuestProfile';
import CompanyCourse from './Dashboards/Company/Courses/CompanyCourse';
import CompanyMembership from './Dashboards/Company/Membership/CompanyMembership';
import Staff from './Dashboards/Company/Staff/Staff';
import EditStaff from './Dashboards/Company/Staff/EditStaff';
import AddStaff from './Dashboards/Company/Staff/AddStaff';
import StaffCourse from './Dashboards/Staff/Courses/StaffCourse';
import StaffLessons from './Dashboards/Staff/Lesson/StaffLessons';
import CompanyLessons from './Dashboards/Company/Lesson/CompanyLessons';
import Consultancy from './Dashboards/Admin/Pages/Consultancy';
import AddConsultancy from './Dashboards/Admin/Pages/AddConsultancy';
import EditConsultancy from './Dashboards/Admin/Pages/EditConsultancy';

const App = () => {
    const token = localStorage.getItem('accesstoken')
    const role = JSON.parse(localStorage.getItem('user'))
    console.log(role, "token");
    return (
        <>
            <BrowserRouter>
                <ToastContainer />
                <Toaster
                    position="top-right"
                    reverseOrder={false}
                />
                {!token ?
                    <>
                        {/* {pathname === '/admin/login' ? */}
                        <Routes>
                            <Route path='/admin/login' element={<Login />} />
                        </Routes>
                        <Routes>
                            <Route path="/" element={<LayoutGuest><GuestHome /></LayoutGuest>} />
                            <Route path="/login" element={<LayoutGuest><LoginPage /></LayoutGuest>} />
                            <Route path="/course" element={<LayoutGuest><Courses /></LayoutGuest>} />
                            <Route path="/coursedetail/:id" element={<LayoutGuest><CourseDetail /></LayoutGuest>} />
                            <Route path="/about" element={<LayoutGuest><About /></LayoutGuest>} />
                            <Route path="/constancy" element={<LayoutGuest><Constancy /></LayoutGuest>} />
                            <Route path="/contact" element={<LayoutGuest><Contact /></LayoutGuest>} />
                            <Route path="/guesteditprofile" element={<LayoutGuest><GuestProfile /></LayoutGuest>} />
                        </Routes>
                    </>
                    :
                    role?.role_id == 1 ?
                        <AdminLayout>
                            <Routes>
                                <Route path='/admin/dashboard' element={<Dashboard />} />
                                <Route path='/admin/profile' element={<Profile />} />
                                <Route path='/admin/course/:name' element={<Course />} />
                                <Route path='/admin/editcourse/:id' element={<EditCourses />} />
                                <Route path='/admin/profileedit' element={<PrifileEdit />} />
                                <Route path='/admin/membership' element={<Membership />} />
                                <Route path='/admin/addmembership' element={<AddMembership />} />
                                <Route path='/admin/editmembership/:id' element={<EditMembership />} />
                                <Route path='/admin/subadmin' element={<SubAdmin />} />
                                <Route path='/admin/addsubadmin' element={<AddSubadmin />} />
                                <Route path='/admin/editsubadmin/:id' element={<EditSubadmin />} />
                                <Route path='/admin/comapny' element={<Comapny />} />
                                <Route path='/admin/addcourse' element={<AddCourse />} />
                                <Route path='/admin/lessons/:id' element={<Lessons />} />
                                <Route path='/admin/addlesson/:id' element={<AddLessons />} />
                                <Route path='/admin/editlesson/:id' element={<EditLesson />} />
                                <Route path='/admin/consultancy' element={<Consultancy />} />
                                <Route path='/admin/addconsultancy' element={<AddConsultancy />} />
                                <Route path='/admin/editconsultancy' element={<EditConsultancy />} />
                            </Routes>
                        </AdminLayout>
                        :
                        role?.role_id == 2 ?
                            <AdminLayout>
                                <Routes>
                                    <Route path='/subadmin/dashboard' element={<Dashboard />} />
                                    <Route path='/subadmin/profile' element={<Profile />} />
                                    <Route path='/admin/course/:name' element={<Course />} />
                                    <Route path='/admin/editcourse/:id' element={<EditCourses />} />
                                    <Route path='/subadmin/profileedit' element={<PrifileEdit />} />
                                    <Route path='/subadmin/membership' element={<Membership />} />
                                    <Route path='/admin/addmembership' element={<AddMembership />} />
                                    <Route path='/admin/editmembership/:id' element={<EditMembership />} />
                                    <Route path='/subadmin/comapny' element={<Comapny />} />
                                    <Route path='/admin/addcourse' element={<AddCourse />} />
                                    <Route path='/admin/lessons/:id' element={<Lessons />} />
                                    <Route path='/admin/addlesson/:id' element={<AddLessons />} />
                                    <Route path='/admin/editlesson/:id' element={<EditLesson />} />
                                    <Route path='/subadmin/consultancy' element={<Consultancy />} />
                                </Routes>
                            </AdminLayout>
                            :
                            role?.role_id == 3 ?
                                <AdminLayout>
                                    <Routes>
                                        <Route path='/company/dashboard' element={<Dashboard />} />
                                        <Route path='/company/profile' element={<Profile />} />
                                        <Route path='/company/course' element={<CompanyCourse />} />
                                        <Route path='/company/profileedit' element={<PrifileEdit />} />
                                        <Route path='/company/membership' element={<CompanyMembership />} />
                                        <Route path='/company/lessons/:id' element={<CompanyLessons />} />
                                        <Route path='/company/staff' element={<Staff />} />
                                        <Route path='/company/editstaff/:id' element={<EditStaff />} />
                                        <Route path='/company/addstaff' element={<AddStaff />} />

                                    </Routes>
                                </AdminLayout>
                                :
                                role?.role_id == 4 ?
                                    <AdminLayout>
                                        <Routes>
                                            <Route path='/staff/dashboard' element={<Dashboard />} />
                                            <Route path='/staff/profile' element={<Profile />} />
                                            <Route path='/staff/course' element={<StaffCourse />} />
                                            <Route path='/staff/profileedit' element={<PrifileEdit />} />
                                            <Route path='/staff/lessons/:id' element={<StaffLessons />} />
                                        </Routes>
                                    </AdminLayout>
                                    :
                                    null
                }

            </BrowserRouter>
        </>
    )
}





export default App;


