/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const HeroSection02 = () => {
  return (
    <>
      {/* <!-- theme fixed shadow --> */}
      <div>
        <div className="theme__shadow__circle"></div>
        <div className="theme__shadow__circle shadow__right"></div>
      </div>
      {/* <!-- theme fixed shadow --> */}

      {/* <!-- herobannerarea__section__start --> */}
      <div className="herobannerarea herobannerarea__box m-0" style={{background:"#F9F9F9"}}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
              data-aos="fade-up"
            >
              <div className="herobannerarea__content__wraper">
                <div className="herobannerarea__title">
                  <div className="herobannerarea__title__heading__2">
                    <div className="heading herobannerarea__text">
                        <h2><span>Transforming Business, </span> Empowering People.</h2>
                        <p>Welcome to THE LEARNER LOUNGE the pioneering platform dedicated to reshaping the landscape of modern business through transformative online training. In today's fast-evolving corporate world, staying ahead means constantly adapting and learning. We understand this, and that's why we've created a range of comprehensive training modules designed to empower individuals and companies alike.</p>
                        <h2>Our <span> Mission</span> </h2> 
                    </div>                       
                  </div>
                </div>

                <div className="herobannerarea__text">
                  <p>
                  At THE LEARNER LOUNGE our mission is simple yet profound: to facilitate seamless business transformations by equipping professionals with cutting-edge knowledge and skills. We believe in the power of learning to ignite change, foster growth, and drive success in any organization.{" "}
                  </p>
                </div>
                <div className="hreobannerarea__button">
                  <a className="herobannerarea__button__1" href="#">
                  Our Services
                  <i class="fa-solid fa-greater-than px-2"></i>
                  </a>
                  <a className="herobannerarea__button__2" href="#">
                  Contact
                  <i class="fa-solid fa-greater-than px-2"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
              data-aos="fade-up"
            >
              <div className="aboutarea__img__inner text-center mt-4">
                <div className="" data-tilt>
                  <img
                    loading="lazy"
                    className="aboutimg__2"
                    src="assets/img/image2.png"
                    alt="aboutimg"
                  />
                  <img className="images-change" src="assets/img/Group.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- herobannerarea__section__end--> */}
    </>
  );
};

export default HeroSection02;
