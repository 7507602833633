/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    const role = JSON.parse(localStorage.getItem('user'))
    return (
        <>
            <header style={{
                position: 'sticky',
                top: 0,
                bottom: 0,
                zIndex: 10000
            }}>
                <div class="headerarea headerarea__3 header__sticky header__area" style={{ backgroundColor: `#fff` }}>
                    <div class="container desktop__menu__wrapper">
                        <div class="row">
                            <div class="col-xl-2 col-lg-2 col-md-6">
                                <div class="headerarea__left">
                                    <div class="headerarea__left__logo m-2">
                                        <Link href="/admin/dashboard"><img loading="lazy" src="/assets/img/thelearnerlounge-01.png" alt="logo" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-7 col-lg-7 main_menu_wrap">
                                {/* <div class="headerarea__main__menu">
                                    <nav>
                                        <ul>


                                            <li class="mega__menu position-static">
                                                <a class="headerarea__has__dropdown" href="index.html">Demos<i
                                                    class="icofont-rounded-down"></i> </a>
                                                <div class="headerarea__submenu mega__menu__wrapper mega__menu__grid__5">

                                                    <ul class="nav  tab__button__wrap" id="myTab2" role="tablist">
                                                        <li class="nav-item" role="presentation">
                                                            <button class="single__tab__link active" data-bs-toggle="tab"
                                                                data-bs-target="#projects__light"
                                                                type="button">Light</button>
                                                        </li>
                                                        <li class="nav-item" role="presentation">
                                                            <button class="single__tab__link" data-bs-toggle="tab"
                                                                data-bs-target="#projects__dark" type="button">Dark</button>
                                                        </li>
                                                    </ul>


                                                    <div class="tab-content tab__content__wrapper" id="myTabContent2">
                                                        <div class="tab-pane fade active show" id="projects__light"
                                                            role="tabpanel" aria-labelledby="projects__light">

                                                            <div class="row">

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../index-2.html">
                                                                            <img loading="lazy" src="../img/mega/home-1.png"
                                                                                alt="Mega Menu"/>
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Default)</span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-2.html">
                                                                            <img loading="lazy" src="../img/mega/home-2.png"
                                                                                alt="Mega Menu"/>
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Elegant)</span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-3.html">
                                                                            <img loading="lazy" src="../img/mega/home-3.png"
                                                                                alt="Mega Menu"/>
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Classic)</span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-4.html">
                                                                            <img loading="lazy" src="../img/mega/home-4.png"
                                                                                alt="Mega Menu"/>
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Classic LMS)</span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-5.html">
                                                                            <img loading="lazy" src="../img/mega/home-5.png"
                                                                                alt="Mega Menu"/>
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Online Course)</span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-6.html">
                                                                            <img loading="lazy" src="../img/mega/home-6.png"
                                                                                alt="Mega Menu"/>
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Marketplace)</span>
                                                                        </a>
                                                                        <span class="mega__menu__thumb__label">New</span>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-7.html">
                                                                            <img loading="lazy" src="../img/mega/home-7.png"
                                                                                alt="Mega Menu"/>
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (University)</span>
                                                                        </a>
                                                                        <span class="mega__menu__thumb__label">New</span>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-8.html">
                                                                            <img loading="lazy" src="../img/mega/home-8.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (eCommerce)</span>
                                                                        </a>
                                                                        <span class="mega__menu__thumb__label">New</span>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-9.html">
                                                                            <img loading="lazy" src="../img/mega/home-9.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Kindergarten)</span>
                                                                        </a>
                                                                        <span class="mega__menu__thumb__label">New</span>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-10.html">
                                                                            <img loading="lazy" src="../img/mega/home-10.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Machine Learning)</span>
                                                                        </a>
                                                                        <span class="mega__menu__thumb__label">New</span>
                                                                    </div>
                                                                </div>



                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-11.html">
                                                                            <img loading="lazy" src="../img/mega/home-11.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Single Course)</span>
                                                                        </a>
                                                                        <span class="mega__menu__thumb__label">New</span>
                                                                    </div>
                                                                </div>



                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="#">
                                                                            <img loading="lazy" src="../img/mega/coming.png"
                                                                                alt="Mega Menu 1" />
                                                                                <span class="mega__menu__thumb__title">Layout
                                                                                    Coming Soon 1</span>
                                                                        </a>
                                                                    </div>
                                                                </div>


                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="#">
                                                                            <img loading="lazy" src="../img/mega/coming.png"
                                                                                alt="Mega Menu 2" />
                                                                                <span class="mega__menu__thumb__title">Layout
                                                                                    Coming Soon 2</span>
                                                                        </a>
                                                                    </div>
                                                                </div>


                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="#">
                                                                            <img loading="lazy" src="../img/mega/coming.png"
                                                                                alt="Mega Menu 3" />
                                                                                <span class="mega__menu__thumb__title">Layout
                                                                                    Coming Soon 3</span>
                                                                        </a>
                                                                    </div>
                                                                </div>


                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="#">
                                                                            <img loading="lazy" src="../img/mega/coming.png"
                                                                                alt="Mega Menu 4" />
                                                                                <span class="mega__menu__thumb__title">Layout
                                                                                    Coming Soon 4</span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class="tab-pane fade" id="projects__dark" role="tabpanel"
                                                            aria-labelledby="projects__dark">

                                                            <div class="row">

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../index-dark.html">
                                                                            <img loading="lazy" src="../img/mega/home-1-dark.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Default)</span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-2-dark.html">
                                                                            <img loading="lazy" src="../img/mega/home-2-dark.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Elegant)</span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-3-dark.html">
                                                                            <img loading="lazy" src="../img/mega/home-3-dark.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Classic)</span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-4-dark.html">
                                                                            <img loading="lazy" src="../img/mega/home-4-dark.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Classic LMS)</span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-5-dark.html">
                                                                            <img loading="lazy" src="../img/mega/home-5-dark.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Online Course)</span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-6-dark.html">
                                                                            <img loading="lazy" src="../img/mega/home-6-dark.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Marketplace)</span>
                                                                        </a>
                                                                        <span class="mega__menu__thumb__label">New</span>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-7-dark.html">
                                                                            <img loading="lazy" src="../img/mega/home-7-dark.png"
                                                                                alt="Mega Menu"/>
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (University)</span>
                                                                        </a>
                                                                        <span class="mega__menu__thumb__label">New</span>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-8-dark.html">
                                                                            <img loading="lazy" src="../img/mega/home-8-dark.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (eCommerce)</span>
                                                                        </a>
                                                                        <span class="mega__menu__thumb__label">New</span>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-9-dark.html">
                                                                            <img loading="lazy" src="../img/mega/home-9-dark.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Kindergarten)</span>
                                                                        </a>
                                                                        <span class="mega__menu__thumb__label">New</span>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="../home-10-dark.html">
                                                                            <img loading="lazy" src="../img/mega/home-10-dark.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Machine Learning)</span>
                                                                        </a>
                                                                        <span class="mega__menu__thumb__label">New</span>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="home-11-dark.html">
                                                                            <img loading="lazy" src="../img/mega/home-11-dark.png"
                                                                                alt="Mega Menu" />
                                                                                <span class="mega__menu__thumb__title">Home
                                                                                    (Single Course)</span>
                                                                        </a>
                                                                        <span class="mega__menu__thumb__label">New</span>
                                                                    </div>
                                                                </div>


                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="#">
                                                                            <img loading="lazy" src="../img/mega/coming-dark.png"
                                                                                alt="Mega Menu 1" />
                                                                                <span class="mega__menu__thumb__title">Layout
                                                                                    Coming Soon 1</span>
                                                                        </a>
                                                                    </div>
                                                                </div>


                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="#">
                                                                            <img loading="lazy" src="../img/mega/coming-dark.png"
                                                                                alt="Mega Menu 2" />
                                                                                <span class="mega__menu__thumb__title">Layout
                                                                                    Coming Soon 2</span>
                                                                        </a>
                                                                    </div>
                                                                </div>


                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="#">
                                                                            <img loading="lazy" src="../img/mega/coming-dark.png"
                                                                                alt="Mega Menu 3" />
                                                                                <span class="mega__menu__thumb__title">Layout
                                                                                    Coming Soon 3</span>
                                                                        </a>
                                                                    </div>
                                                                </div>


                                                                <div class="col-3 mega__menu__single__wrap">
                                                                    <div class="mega__menu__thumb">
                                                                        <a href="#">
                                                                            <img loading="lazy" src="../img/mega/coming-dark.png"
                                                                                alt="Mega Menu 4" />
                                                                                <span class="mega__menu__thumb__title">Layout
                                                                                    Coming Soon 4</span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </li>

                                            <li class="mega__menu position-static">
                                                <a class="headerarea__has__dropdown" href="about.html">Pages<i
                                                    class="icofont-rounded-down"></i> </a>
                                                <div class="headerarea__submenu mega__menu__wrapper">

                                                    <div class="row">
                                                        <div class="col-3 mega__menu__single__wrap">
                                                            <h4 class="mega__menu__title"><a href="#">Get Started 1 </a>
                                                            </h4>
                                                            <ul class="mega__menu__item">
                                                                <li><a href="../about.html">About <span
                                                                    class="mega__menu__label">Sale
                                                                    Everything</span></a></li>
                                                                <li><a href="../about-dark.html">About (Dark)<span
                                                                    class="mega__menu__label new">New</span></a>
                                                                </li>
                                                                <li><a href="../blog.html">Blog</a></li>
                                                                <li><a href="../blog-dark.html">Blog (Dark)</a></li>
                                                                <li><a href="../blog-details.html">Blog Details</a></li>
                                                                <li><a href="../blog-details-dark.html">Blog Details
                                                                    (Dark)</a></li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-3 mega__menu__single__wrap">
                                                            <h4 class="mega__menu__title"><a href="#">Get Started 2 </a>
                                                            </h4>
                                                            <ul class="mega__menu__item">
                                                                <li><a href="../error.html">Error 404</a></li>
                                                                <li><a href="../error-dark.html">Error (Dark)</a></li>
                                                                <li><a href="../event-details.html">Event Details</a></li>
                                                                <li><a href="../zoom/zoom-meetings.html">Zoom<span
                                                                    class="mega__menu__label">Online Call</span></a>
                                                                </li>
                                                                <li><a href="../zoom/zoom-meetings-dark.html">Zoom Meeting
                                                                    (Dark)</a></li>
                                                                <li><a href="../zoom/zoom-meeting-details.html">Zoom Meeting
                                                                    Details</a></li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-3 mega__menu__single__wrap">
                                                            <h4 class="mega__menu__title"><a href="#">Get Started 3</a></h4>
                                                            <ul class="mega__menu__item">
                                                                <li><a href="../zoom/zoom-meeting-details-dark.html">Meeting
                                                                    Details (Dark)</a></li>
                                                                <li><a href="../login.html">Login</a></li>
                                                                <li><a href="../login-dark.html">Login (Dark)</a></li>
                                                                <li><a href="../maintenance.html">Maintenance</a></li>
                                                                <li><a href="../maintenance-dark.html">Maintenance Dark</a></li>
                                                                <li><a href="#">Terms & Condition</a></li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-3 mega__menu__single__wrap">
                                                            <h4 class="mega__menu__title"><a href="#">Get Started 4</a></h4>
                                                            <ul class="mega__menu__item">
                                                                <li><a href="#">Terms & Condition (Dark)</a></li>
                                                                <li><a href="#">Privacy Policy</a></li>
                                                                <li><a href="#">Privacy Policy (Dark)</a></li>
                                                                <li><a href="#">Success Stories</a></li>
                                                                <li><a href="#">Success Stories (Dark)</a></li>
                                                                <li><a href="#">Work Policy</a></li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-12 mega__menu__single__wrap sp_top_30">
                                                            <div class="mega__menu__img">
                                                                <a href="#"><img loading="lazy" src="../img/mega/mega_menu_2.png"
                                                                    alt="Mega Menu" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </li>


                                            <li class="mega__menu position-static">
                                                <a class="headerarea__has__dropdown" href="../course.html">Courses<i
                                                    class="icofont-rounded-down"></i> </a>
                                                <div class="headerarea__submenu mega__menu__wrapper">

                                                    <div class="row">
                                                        <div class="col-3 mega__menu__single__wrap">
                                                            <h4 class="mega__menu__title"><a href="#">Get Started 1 </a>
                                                            </h4>
                                                            <ul class="mega__menu__item">
                                                                <li><a href="../course.html">Grid <span
                                                                    class="mega__menu__label">All Courses</span></a>
                                                                </li>
                                                                <li><a href="../course-dark.html">Course Grid (Dark)</a>
                                                                </li>
                                                                <li><a href="../course-grid.html">Course Grid</a></li>
                                                                <li><a href="../course-grid-dark.html">Course Grid
                                                                    (Dark)</a></li>
                                                                <li><a href="../course-list.html">Course List</a></li>
                                                                <li><a href="../course-list-dark.html">Course List
                                                                    (Dark)</a></li>

                                                            </ul>
                                                        </div>
                                                        <div class="col-3 mega__menu__single__wrap">
                                                            <h4 class="mega__menu__title"><a href="#">Get Started 2 </a>
                                                            </h4>
                                                            <ul class="mega__menu__item">
                                                                <li><a href="../course-details.html">Course Details</a></li>
                                                                <li><a href="../course-details-dark.html">Course Details
                                                                    (Dark)</a></li>
                                                                <li><a href="../course-details-2.html">Course Details 2</a>
                                                                </li>
                                                                <li><a href="../course-details-2-dark.html">Details 2
                                                                    (Dark)</a></li>
                                                                <li><a href="../course-details-3.html">Course Details 3</a>
                                                                </li>
                                                                <li><a href="../course-details-3.html">Details 3 (Dark)</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-3 mega__menu__single__wrap">
                                                            <h4 class="mega__menu__title"><a href="#">Get Started 3</a></h4>
                                                            <ul class="mega__menu__item">
                                                                <li><a href="become-an-instructor.html">Become
                                                                    An Instructor</a></li>
                                                                    <li><a href="create-course.html">Create Course
                                                                        <span class="mega__menu__label">Career</span></a>
                                                                    </li>
                                                                    <li><a href="../instructor.html">Instructor</a></li>
                                                                    <li><a href="../instructor-dark.html">Instructor (Dark)</a>
                                                                    </li>
                                                                    <li><a href="../instructor-details.html">Instructor
                                                                        Details</a></li>
                                                                    <li><a href="../lesson.html">Course Lesson<span
                                                                        class="mega__menu__label new">New</span></a>
                                                                    </li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-3 mega__menu__single__wrap">
                                                            <div class="mega__menu__img">
                                                                <a href="#"><img loading="lazy" src="../img/mega/mega_menu_1.png"
                                                                    alt="Mega Menu" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </li>


                                            <li><a class="headerarea__has__dropdown"
                                                href="instructor-dashboard.html">Dashboard
                                                <i class="icofont-rounded-down"></i>
                                            </a>
                                                <ul class="headerarea__submenu headerarea__submenu--third--wrap">
                                                    <li><a href="admin-dashboard.html">Admin <i
                                                        class="icofont-rounded-right"></i></a>

                                                        <ul class="headerarea__submenu--third">
                                                            <li><a href="admin-dashboard.html">Admin
                                                                Dashboard</a></li>
                                                            <li><a href="admin-profile.html">Admin Profile</a>
                                                            </li>
                                                            <li><a href="admin-message.html">Message</a></li>
                                                            <li><a href="admin-wishlist.html">Popular
                                                                Courses</a></li>
                                                            <li><a href="admin-reviews.html">Review</a></li>
                                                            <li><a href="admin-quiz-attempts.html">Admin
                                                                Quiz</a></li>

                                                            <li><a href="admin-settings.html">Settings</a></li>
                                                        </ul>

                                                    </li>
                                                    <li>
                                                        <a href="instructor-dashboard.html">Instructor <i
                                                            class="icofont-rounded-right"></i></a>
                                                        <ul class="headerarea__submenu--third">
                                                            <li><a href="instructor-dashboard.html">Inst.
                                                                Dashboard</a></li>
                                                            <li><a href="instructor-profile.html">Inst.
                                                                Profile</a></li>
                                                            <li><a href="instructor-message.html">Message</a></li>
                                                            <li><a href="instructor-wishlist.html">Wishlist</a>
                                                            </li>
                                                            <li><a href="instructor-reviews.html">Review</a>
                                                            </li>
                                                            <li><a href="instructor-my-quiz-attempts.html">My
                                                                Quiz</a></li>
                                                            <li><a href="instructor-order-history.html">Order
                                                                History</a></li>
                                                            <li><a href="instructor-course.html">My Courses</a>
                                                            </li>
                                                            <li><a
                                                                href="instructor-announcments.html">Announcements</a>
                                                            </li>
                                                            <li><a href="instructor-quiz-attempts.html">Quiz
                                                                Attempts</a></li>
                                                            <li><a
                                                                href="instructor-assignments.html">Assignment</a>
                                                            </li>
                                                            <li><a href="instructor-settings.html">Settings</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="student-dashboard.html">Student <i
                                                        class="icofont-rounded-right"></i></a>
                                                        <ul class="headerarea__submenu--third">
                                                            <li><a href="student-dashboard.html">Dashboard</a>
                                                            </li>
                                                            <li><a href="student-profile.html">Profile</a></li>
                                                            <li><a href="student-message.html">Message</a></li>
                                                            <li><a href="student-enrolled-courses.html">Enrolled
                                                                Courses</a></li>
                                                            <li><a href="student-wishlist.html">Wishlist</a>
                                                            </li>
                                                            <li><a href="student-reviews.html">Review</a></li>
                                                            <li><a href="student-my-quiz-attempts.html">My
                                                                Quiz</a></li>
                                                            <li><a
                                                                href="student-assignments.html">Assignment</a>
                                                            </li>
                                                            <li><a href="student-settings.html">Settings</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li><a class="headerarea__has__dropdown" href="ecommerce/shop.html">eCommerce
                                                <i class="icofont-rounded-down"></i>
                                            </a>
                                                <ul class="headerarea__submenu">
                                                    <li><a href="../ecommerce/shop.html">Shop<span class="mega__menu__label">Online
                                                        Store</span></a></li>
                                                    <li><a href="../ecommerce/product-details.html">Product Details</a></li>
                                                    <li><a href="../ecommerce/cart.html">Cart</a></li>
                                                    <li><a href="../ecommerce/checkout.html">Checkout</a></li>
                                                    <li><a href="../ecommerce/wishlist.html">Wishlist</a></li>
                                                </ul>
                                            </li>


                                        </ul>
                                    </nav>
                                </div> */}
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="headerarea__right">

                                    <div class="dashboard__nav__title">
                                        <h6>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg>
                                            Welcome, {role?.fname} </h6>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>


                    <div class="container-fluid mob_menu_wrapper">
                        <div class="row align-items-center">
                            <div class="col-6">
                                <div class="mobile-logo">
                                    <Link href="/admin/dashboard"><img loading="lazy" src="/assets/img/thelearnerlounge-01.png" alt="logo" /></Link>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="header-right-wrap">

                                    {/* <div class="headerarea__right">
                                        <div class="header__cart">
                                            <a href="#"> <i class="icofont-cart-alt"></i></a>
                                            <div class="header__right__dropdown__wrapper">
                                                <div class="header__right__dropdown__inner">
                                                    <div class="single__header__right__dropdown">

                                                        <div class="header__right__dropdown__img">
                                                            <a href="#">
                                                                <img loading="lazy" src="../img/grid/cart1.jpg" alt="photo" />
                                                            </a>
                                                        </div>
                                                        <div class="header__right__dropdown__content">

                                                            <a href="shop-product.html">Web Directory</a>
                                                            <p>1 x <span class="price">$ 80.00</span></p>

                                                        </div>
                                                        <div class="header__right__dropdown__close">
                                                            <a href="#"><i class="icofont-close-line"></i></a>
                                                        </div>
                                                    </div>

                                                    <div class="single__header__right__dropdown">

                                                        <div class="header__right__dropdown__img">
                                                            <a href="#">
                                                                <img loading="lazy" src="../img/grid/cart2.jpg" alt="photo" />
                                                            </a>
                                                        </div>
                                                        <div class="header__right__dropdown__content">

                                                            <a href="shop-product.html">Design Minois</a>
                                                            <p>1 x <span class="price">$ 60.00</span></p>

                                                        </div>
                                                        <div class="header__right__dropdown__close">
                                                            <a href="#"><i class="icofont-close-line"></i></a>
                                                        </div>
                                                    </div>

                                                    <div class="single__header__right__dropdown">

                                                        <div class="header__right__dropdown__img">
                                                            <a href="#">
                                                                <img loading="lazy" src="../img/grid/cart3.jpg" alt="photo" />
                                                            </a>
                                                        </div>
                                                        <div class="header__right__dropdown__content">

                                                            <a href="shop-product.html">Crash Course</a>
                                                            <p>1 x <span class="price">$ 70.00</span></p>

                                                        </div>
                                                        <div class="header__right__dropdown__close">
                                                            <a href="#"><i class="icofont-close-line"></i></a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <p class="dropdown__price">Total: <span>$1,100.00</span>
                                                </p>
                                                <div class="header__right__dropdown__button">
                                                    <a href="#" class="white__color">VIEW
                                                        CART</a>
                                                    <a href="#" class="blue__color">CHECKOUT</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div class="mobile-off-canvas">
                                        <a class="mobile-aside-button" href="#"><i class="icofont-navigation-menu"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header >

        </>
    )
}

export default Header