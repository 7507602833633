import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utlilites/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  memberships: [],

};


const slice = createSlice({
  name: 'membership',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET spell
    getMembershipsuccess(state, action) {
      state.isLoading = false;
      state.memberships = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getMembership() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      await axiosInstance.get(`package`).then((response) => {
        console.log(response, 'memberships--->>>>')
        dispatch(slice.actions.getMembershipsuccess(response?.data?.packages));
      })
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------