import React from "react";
import AboutSection01 from "../../../../Components/Guest/About/AboutSection01";

const About = () => {
  return (
    <>
      <AboutSection01 />
    </>
  );
};

export default About;
