/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  initialValuesGuest,
  signupSchemaGuest,
  initialValuesCompany,
  signupSchemaCompany,
} from "./schema/Shcema";
import axiosInstance from "../../../utlilites/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader/Loader";

const Signup = () => {
  const data = useLocation();
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);
  const rollData = data.state.data;
  localStorage.setItem("rollLogin", rollData);
  const localRoll = localStorage.getItem("rollLogin");
  const handlerChange = (e) => {
    console.log(e.target.files[0]);
    setImage(e.target.files[0]);
  };

  const documentLoginProject = document?.getElementById("projects__one");
  const documentLogin = document?.getElementById("login");
  const documentSignupProject = document?.getElementById("projects__two");
  const documentSignup = document?.getElementById("signup");

  function getInitialValues(roll) {
    switch (roll) {
      case "guest":
        return initialValuesGuest;
      case "company":
        return initialValuesCompany;
      default:
        return null;
    }
  }

  function getValidationSchema(roll) {
    switch (roll) {
      case "guest":
        return signupSchemaGuest;
      case "company":
        return signupSchemaCompany;
      default:
        return null;
    }
  }

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: getInitialValues(localRoll),
    validationSchema: getValidationSchema(localRoll),
    onSubmit: (values) => {
      console.log(values);
      const formData = new FormData();
      formData.append("fname", values.fname);
      formData.append("lname", values.lname);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("age", values.age);
      formData.append("company_name", values.company_name);
      formData.append("description", values.description);
      formData.append("address", values.address);
      formData.append("state", values.state);
      formData.append("country", values.country);
      formData.append("city", values.city);
      formData.append("logo", image);
      formData.append("password_confirmation", values.password_confirmation);

      if (localRoll === "guest") {
        setLoader(true);
        axiosInstance
          .post("register", values)
          .then((data) => {
            if(data.data.status === true){
            setLoader(true);
            console.log(data);
            resetForm();
            toast.success("Guest Register Successfully !" , {
              position: "bottom-left",
            });
            setLoader(false);
            if (
              documentLoginProject &&
              documentLogin &&
              documentSignupProject &&
              documentSignup
            ) {
              documentLoginProject.classList.add(
                "active",
                "show"
              );
              documentLogin.classList.add("active");
              documentSignupProject.classList.remove("active", "show");
              documentSignup.classList.remove("active" , "show");
            }
          }
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.message , {
              position: "bottom-left",
            });
            setLoader(false);
          });
      } else if (localRoll === "company") {
        setLoader(true);
        axiosInstance
          .post("client/create/company", formData)
          .then((data) => {
            setLoader(true);
            console.log(data);
            resetForm();
            toast.success("Company Register Successfully !" , {
              position: "bottom-left",
            });
            setLoader(false);
            if (
              documentLoginProject &&
              documentLogin &&
              documentSignupProject &&
              documentSignup
            ) {
              documentLoginProject.classList.add(
                "active",
                "show"
              );
              documentLogin.classList.add("active");
              documentSignupProject.classList.remove("active", "show");
              documentSignup.classList.remove("active" , "show");
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.message , {
              position: "bottom-left",
            });
            setLoader(false);
          });
      }
    },
  });
  return (
    <>
      {localRoll === "guest" ? (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">First Name</label>
                <input
                  className="common__login__input"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="fname"
                  value={values.fname}
                  placeholder="First Name"
                />
              </div>
              {errors.fname && touched.fname ? (
                <span className="text-danger fs-6">{errors.fname}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Last Name</label>
                <input
                  className="common__login__input"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="lname"
                  value={values.lname}
                  placeholder="Last Name"
                />
              </div>
              {errors.lname && touched.lname ? (
                <span className="text-danger fs-6">{errors.lname}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Email</label>
                <input
                  className="common__login__input"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="email"
                  value={values.email}
                  placeholder="Email"
                />
              </div>
              {errors.email && touched.email ? (
                <span className="text-danger fs-6">{errors.email}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Password</label>
                <input
                  className="common__login__input"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="password"
                  value={values.password}
                  placeholder="Password"
                />
              </div>
              {errors.password && touched.password ? (
                <span className="text-danger fs-6">{errors.password}</span>
              ) : null}
            </div>
            <div className="col-xl-12">
              <div className="login__form">
                <label className="form__label">Password_Confirmation</label>
                <input
                  className="common__login__input"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="password_confirmation"
                  value={values.password_confirmation}
                  placeholder="Password_Confirmation"
                />
              </div>
            </div>
            {errors.password_confirmation && touched.password_confirmation ? (
              <span className="text-danger fs-6">
                {errors.password_confirmation}
              </span>
            ) : null}
          </div>
          <div className="login__button">
            <button
              className="w-100 text-center text-white border-0 py-2 rounded"
              type="submit"
              style={{ background: "#E19826" }}
            >
              Sign up
              {loader ? <Loader /> : null}
            </button>
          </div>
        </form>
      )  : localRoll === "company" ? (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Fname</label>
                <input
                  className="common__login__input"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="fname"
                  value={values.fname}
                  placeholder="Name"
                />
              </div>
              {errors.fname && touched.fname ? (
                <span className="text-danger fs-6">{errors.fname}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Lname</label>
                <input
                  className="common__login__input"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="lname"
                  value={values.lname}
                  placeholder="Lname"
                />
              </div>
              {errors.lname && touched.lname ? (
                <span className="text-danger fs-6">{errors.lname}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Email</label>
                <input
                  className="common__login__input"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="email"
                  value={values.email}
                  placeholder="email"
                />
              </div>
              {errors.email && touched.email ? (
                <span className="text-danger fs-6">{errors.email}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Password</label>
                <input
                  className="common__login__input"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="password"
                  value={values.password}
                  placeholder="password"
                />
              </div>
              {errors.password && touched.password ? (
                <span className="text-danger fs-6">{errors.password}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Age</label>
                <input
                  className="common__login__input"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="age"
                  value={values.age}
                  placeholder="age"
                />
              </div>
              {errors.age && touched.age ? (
                <span className="text-danger fs-6">{errors.age}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Company_name</label>
                <input
                  className="common__login__input"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="company_name"
                  value={values.name}
                  placeholder="company_name"
                />
              </div>
              {errors.company_name && touched.company_name ? (
                <span className="text-danger fs-6">{errors.company_name}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Description</label>
                <input
                  className="common__login__input"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="description"
                  value={values.description}
                  placeholder="description"
                />
              </div>
              {errors.description && touched.description ? (
                <span className="text-danger fs-6">{errors.description}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Address</label>
                <input
                  className="common__login__input"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="address"
                  value={values.address}
                  placeholder="address"
                />
              </div>
              {errors.address && touched.address ? (
                <span className="text-danger fs-6">{errors.address}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">State</label>
                <input
                  className="common__login__input"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="state"
                  value={values.state}
                  placeholder="state"
                />
              </div>
              {errors.state && touched.state ? (
                <span className="text-danger fs-6">{errors.state}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Country</label>
                <input
                  className="common__login__input"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="country"
                  value={values.country}
                  placeholder="country"
                />
              </div>
              {errors.country && touched.country ? (
                <span className="text-danger fs-6">{errors.country}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">City</label>
                <input
                  className="common__login__input"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="city"
                  value={values.city}
                  placeholder="city"
                />
              </div>
              {errors.city && touched.city ? (
                <span className="text-danger fs-6">{errors.city}</span>
              ) : null}
            </div>
            <div className="col-xl-6">
              <div className="login__form">
                <label className="form__label">Logo</label>
                <input
                  className="common__login__input pt-2"
                  type="file"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handlerChange(e);
                    handleChange(e);
                  }}
                  name="logo"
                />
              </div>
              {errors.logo && touched.logo ? (
                <span className="text-danger fs-6">{errors.logo}</span>
              ) : null}
            </div>
            <div className="col-xl-12">
              <div className="login__form">
                <label className="form__label">Password_confirmation</label>
                <input
                  className="common__login__input"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="password_confirmation"
                  value={values.password_confirmation}
                  placeholder="password_confirmation"
                />
              </div>
              {errors.password_confirmation && touched.password_confirmation ? (
                <span className="text-danger fs-6">
                  {errors.password_confirmation}
                </span>
              ) : null}
            </div>
          </div>
          <div className="login__button">
            <button
              className="w-100 text-center text-white border-0 py-2 rounded"
              type="submit"
              style={{ background: "#E19826" }}
            >
              Sign up
              {loader ? <Loader /> : null}
            </button>
          </div>
        </form>
      ) : null}
    </>
  );
};

export default Signup;
