import React from 'react'
import CourseSection01 from '../../../../Components/Guest/Course/CourseSection01'

const Course = () => {
  return (
    <>
      <CourseSection01 />
    </>
  )
}

export default Course
