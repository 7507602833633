import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utlilites/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  Singlecourse: [],

};


const slice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET spell
    getCoursesuccess(state, action) {
      console.log(state.subadmins,"subadmins")
      state.isLoading = false;
      state.Singlecourse = action.payload;
    },
    EmptyCourse(state, action) {
      console.log(state.subadmins,"subadmins")
      state.isLoading = false;
      state.Singlecourse = "";
    },

  },
});

// Reducer
export const {getCoursesuccess,EmptyCourse} = slice.actions
export default slice.reducer;


// ----------------------------------------------------------------------

export function getCourse() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      await axiosInstance.get(`courses`).then((response) => {
        console.log(response, 'courses--->>>>')
        dispatch(slice.actions.getCoursesuccess(response?.data?.courses));
      })
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------