/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from '../../../../Redux/store';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../utlilites/axios';
import toast from 'react-hot-toast';
import { getMembership } from '../../../../Redux/Slices/Membership';
import Loader from '../../../../Components/Guest/loader/Loader';


const Membership = () => {
    const [loader, setLoader] = useState(false);
    const [rowStates, setRowStates] = useState({});
    const { memberships } = useSelector((state) => state.Membership)
    const navigate = useNavigate()
    console.log(memberships, "memberships")


    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(getMembership())
    }, [dispatch])

    const handleDelete = async (id) => {
        try {
            setLoader(true)
            await axiosInstance.get(`delete/package/${id}`).then((response) => {
                if (response?.data?.status) {
                    setLoader(false)
                    console.log(response, 'delete--->>>>')
                    toast.success('Delete SuccessFully')
                    dispatch(getMembership())
                }
            }).catch((errors) => {
                setLoader(false)
                toast.error("Error");
                console.log(errors, 'delete errors')
            })
        } catch (error) {
            setLoader(false)
            toast.error('Error');
            console.log(error, 'delete errors')
        }
    }

    const handleStatus = async (e,id) => {
        setLoader(true);
        try {
            await axiosInstance.get(`package/enable/disable/${id}`).then((response) => {
                if (response?.data?.status) {
                    setLoader(false);
                    console.log(response, 'Status--->>>>')
                    toast.success("Status Changes Successfully")
                    dispatch(getMembership())
                }
            }).catch((errors) => {
                setLoader(false);
                toast.error("Error")
                console.log(errors, 'Status errors')
            })
        } catch (error) {
            setLoader(false);
            toast.error(" Error")
            console.log(error, 'Status errors')
        }
    }

    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="dashboardarea__wraper">
                            <div class="dashboardarea__img">
                                <div class="dashboardarea__inner admin__dashboard__inner">
                                    <div class="dashboardarea__left">
                                        <div class="dashboardarea__left__img">
                                            <img loading="lazy" src="/assets/img/dashbord/dashbord__2.jpg" alt="" />
                                        </div>
                                        <div class="dashboardarea__left__content">
                                            {/* <h5>Hello</h5> */}
                                            <h4>Michle Obema</h4>
                                        </div>
                                    </div>
                                    <div class="dashboardarea__right">
                                        <div class="dashboardarea__right__button">
                                            <Link class="default__button" to="/admin/addmembership">Create a New MemberShip
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="dashboard__content__wraper">
                <div class="dashboard__section__title">
                    <h4>MemberShip</h4>
                </div>


                <div className='row'>
                    {memberships?.map((e) => {
                        return (
                            <>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="pricingarea__content__wraper">
                                        <div className="pricingarea__heading">
                                            <div className="pricingarea__plan__type">
                                                <h6>{e?.company_name}</h6>

                                            </div>

                                            <div className="pricingarea__number">
                                                <h1><span className="price__currency">$</span>{e?.price}<span className="price__durition">/
                                                    month</span></h1>
                                            </div>
                                            <h3>{e?.name}</h3>
                                        </div>
                                        <div className="pricingarea__list">
                                            <p>{e?.description}</p>
                                        </div>
                                        <div className="pricingarea__button">
                                            <div className='row'>
                                                <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                                    <a className="default__button" href="#">Get Started</a>
                                                </div>
                                                <div className='col-xl-6 col-lg-6 col-md-12 col-12 d-flex justify-content-end align-items-center'>
                                                    <FaEdit fontSize={"20px"} onClick={() => navigate(`/admin/editmembership/${e?.id}`)} />
                                                    {
                                                        loader ? <Loader /> :
                                                            <MdDelete fontSize={"20px"} onClick={() => handleDelete(e?.id)} />
                                                    }
                                                    <div className="content">
                                                    <input
                                                        type="checkbox"
                                                        id={`btn-${e?.id}`}
                                                        onChange={() => handleStatus(e, e?.id)}
                                                        checked={rowStates[e?.id] || e?.is_disable === "0" ? true : false}
                                                    />
                                                    <label htmlFor={`btn-${e?.id}`}>
                                                        <span className="track">
                                                            {rowStates[e?.id] || e?.is_disable === "0" === true ? (
                                                                <span className="course_txt">Active</span>
                                                            ) : (
                                                                <span className="course_de-txt">De-active</span>
                                                            )}
                                                        </span>
                                                        <span className="thumb">|||</span>
                                                    </label>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricingarea__text">
                                            {/* <p>No creadit card required</p> */}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>

            </div>






        </>
    )
}

export default Membership