import React, { useState } from 'react'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import axiosInstance from '../../../utlilites/axios';
import Loader from '../../../Components/Guest/loader/Loader';

const AddStaff = () => {
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()


    const AddStaffSchema = Yup.object().shape({
        fname: Yup.string().required('Required'),
        lname: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string()
            .min(8, 'Password should be of minimum 8 characters length')
            .required('Password is required'),
        confirm_password: Yup.string()
            .min(8, 'Password should be of minimum 8 characters length')
            .required('Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    const formik = useFormik({
        initialValues: {
            fname: '',
            lname: '',
            email: '',
            password: '',
            confirm_password: '',
        },
        validationSchema: AddStaffSchema,
        onSubmit: (values) => {
            setLoader(true);
            console.log(values.errors, "formErrors");
            try {
                const AddStaffData = new FormData();
                AddStaffData.append('fname', values?.fname);
                AddStaffData.append('lname', values?.lname);
                AddStaffData.append('email', values?.email);
                AddStaffData.append('password', values?.password);
                AddStaffData.append('password_confirmation', values?.confirm_password);
                AddStaffData.append('role', "4");
                axiosInstance.post("staff/register", AddStaffData)
                    .then((response) => {
                        if (response?.data?.status === true) {
                            console.log(response, "AddStaffData")
                            setLoader(false);
                            toast.success("Add Staff SuccessFully");
                            navigate('/company/staff')
                        }
                    }).catch((errors) => {
                        setLoader(false);
                        console.error(errors);
                        toast.error('error');
                    })

            } catch (error) {
                setLoader(false);
                toast.error('error');
                console.error(error);
            }
        },
    });


    return (
        <>
            <div class="create__course sp_100">
                <div class="container">
                    <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                            <h1>Add Employee</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <form onSubmit={formik.handleSubmit}>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">First Name</label>
                                                <input type="text" placeholder="First Name" name="fname"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.fname}
                                                />
                                            </div>
                                            {formik.errors.fname && <div className="error">{formik.errors.fname}</div>}
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Last Name</label>
                                                <input type="text" placeholder="Last Name" name="lname"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.lname}
                                                />
                                            </div>
                                            {formik.errors.lname && <div className="error">{formik.errors.lname}</div>}
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Email</label>
                                                <input type="email" placeholder="Email" name="email"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                />
                                            </div>
                                            {formik.errors.email && <div className="error">{formik.errors.email}</div>}
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Password</label>
                                                <input type="password" placeholder="Password" name="password"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.password}
                                                />
                                            </div>
                                            {formik.errors.password && <div className="error">{formik.errors.password}</div>}
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                                        <div class="dashboard__form__wraper">
                                            <div class="dashboard__form__input">
                                                <label for="#">Confirm Password</label>
                                                <input type="password" placeholder="Confirm Password" name="confirm_password"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.confirm_password}
                                                />
                                            </div>
                                            {formik.errors.confirm_password && <div className="error">{formik.errors.confirm_password}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="dashboard__form__button">
                                            <button type="submit" class="default__button" >
                                                Add
                                                {
                                                    loader ? <Loader /> : null
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddStaff