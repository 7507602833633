/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../../utlilites/axios";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { MaterialReactTable } from "material-react-table";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";

function Consultancy() {
    const [loader, setLoader] = useState(false);
    const [loaders, setLoaders] = useState(true);

    const [dataConsultancy, setDataConsultancy] = useState([]);
    async function consultancyResponse() {
        setLoaders(true);
        await axiosInstance
            .get("admin/consultancy")
            .then((response) => {
                console.log(response.data);
                setDataConsultancy(response.data.consultancies);
                setLoaders(false);
            })
            .catch((error) => {
                console.log(error);
                setLoaders(false);
            });
    }
    useEffect(() => {
        consultancyResponse();
    }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'title',
                header: 'Title',
                size: 150,
            },
            {
                accessorKey: 'description',
                header: 'Description',
                size: 150,
            },
            {
                accessorKey: 'consultancy_date',
                header: 'Date',
                size: 200,
            },
            {
                accessorKey: 'time',
                header: 'Time',
                Cell: ({ row }) => (
                    <div className="content w-100">
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Slots</option>
                            {
                                row?.original?.slots.map((item, index) => {
                                    return <option key={index} value={item?.time_slot}>{item?.time_slot}</option>
                                })
                            }
                        </select>
                    </div>
                ),
                size: 100,
            },
        ],
        [],
    );

    const handleDelete = (id) => {
        setLoader(true);
        console.log(id);
        axiosInstance.get(`admin/delete/consultancy/${id}`).then((response) => {
            toast.success("Delete Successfully");
            console.log(response.data);
            consultancyResponse();
            setLoader(false);
        }).catch((error) => {
            console.log(error);
            setLoader(false);
        })
    }

    return (
        <>
            <div class="container-fluid full__width__padding">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="dashboardarea__wraper">
                            <div class="dashboardarea__img">
                                <div class="dashboardarea__inner admin__dashboard__inner">
                                    <div class="dashboardarea__left">
                                        <div class="dashboardarea__left__img">
                                            <img loading="lazy" src="/assets/img/dashbord/dashbord__2.jpg" alt="" />
                                        </div>
                                        <div class="dashboardarea__left__content">
                                            <h4>Michle Obema</h4>
                                        </div>
                                    </div>
                                    <div class="dashboardarea__right">
                                        <div class="dashboardarea__right__button">
                                            <Link class="default__button" to="/admin/addconsultancy">Add Consultancy
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loaders ? (
                <div className="d-flex justify-content-center align-items-center">
                <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperClass="color-ring-wrapper"
                    colors={['#DC892B', '#DC892B', '#DC892B', '#DC892B', '#DC892B']}
                    />
                    </div>
            ) : (
                    !dataConsultancy ? <h1 className="text-center">No Record Data</h1> :
                        <MaterialReactTable 
                            columns={columns}
                            data={dataConsultancy}
                            enableRowActions
                            renderRowActions={({ row }) => (
                                <>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            gap: "16px",
                                            justifyContent: "space-between",
                                            alignItems: "flex-center",
                                            flexDirection: "row",
                                            padding: '10px'
                                        }}
                                    >
                                        <Link to={`/admin/editconsultancy`} state={{ data: row.original }} style={{ cursor: "pointer" }}>
                                            <FaEdit fontSize={"20px"} />
                                        </Link>
                                        {
                                            loader ? <ColorRing
                                            visible={true}
                                            height="30"
                                            width="30"
                                            ariaLabel="color-ring-loading"
                                            wrapperClass="color-ring-wrapper"
                                            colors={['#DC892B','#DC892B','#DC892B','#DC892B','#DC892B']}
                                          /> :
                                                <Link style={{ cursor: "pointer" }} >
                                                    <MdDelete fontSize={"20px"} onClick={() => handleDelete(row.original.id)} />
                                                </Link>
                                        }
                                    </div>
                                </>
                            )}
                            positionActionsColumn='last'
                        />
            )}
            
        </>
    )
}

export default Consultancy



