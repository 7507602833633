/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Header = () => {
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const handlerSignout = () => {
    localStorage.clear("userToken");
    toast.success("Signout Successfully!");
    window.location.reload();
  };

  const inside = document?.getElementById("inside");
  const insideNav = () => {
    inside?.classList?.add("inside");
  };
  const insideClose = () => {
    inside?.classList?.remove("inside");
  };
  return (
    <>
      {/* <!-- Dark/Light area start --> */}
      {/* <!-- Dark/Light area end --> */}

      {/* <!-- headar section start --> */}
      <header>
        <div className="headerarea headerarea__2 header__sticky header__area bg-white">
          <div className="container desktop__menu__wrapper">
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-6">
                <div className="headerarea__left">
                  <Link to={"/"}>
                    <div className="headerarea__left__logo">
                    <img src="assets/img/thelearnerlounge-01.png" alt="" width={220} height={120} />
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 main_menu_wrap">
                <div className="headerarea__main__menu">
                  <nav>
                    <ul>
                      <li className="mega__menu position-static">
                        <Link to={"/"} className="headerarea__has__dropdown">
                          Home
                        </Link>
                      </li>

                      <li className="mega__menu position-static">
                        <Link
                          to={"/"}
                          className="headerarea__has__dropdown"
                        >
                          About
                        </Link>
                      </li>

                      <li className="mega__menu position-static" onClick={() => window.location.href = "/course"}>
                        <Link
                          className="headerarea__has__dropdown">
                          Courses
                        </Link>
                      </li>

                      <li>
                        <Link
                          to={"/constancy"}
                          className="headerarea__has__dropdown"
                        >
                          Consultancy
                        </Link>
                      </li>

                      <li>
                        <Link
                          to={"/contact"}
                          className="headerarea__has__dropdown"
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                {token ? (
                  <Dropdown>
                    <div className="dropdown-desing">
                      <Dropdown.Toggle
                        split
                        variant=""
                        id="dropdownBasicButton"
                      />
                      <i className="icofont-user-alt-5"></i>
                    </div>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        onClick={() => navigate("/guesteditprofile")}
                      >
                        Edit profile
                      </Dropdown.Item>
                      <Dropdown.Item href="#" onClick={handlerSignout}>
                        Sign out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <>
                  <Dropdown>
                  <Dropdown.Toggle className="border-0" style={{ backgroundColor: "#DC892B" , fontFamily:'var(--headingFont)' }}>
                    Account Login
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                    <Link to={"/login"} state={{ data: "guest" }}>
                      <div className="headerarea__login">
                        <a href="#" className="w-100">
                          <i className="icofont-user-alt-5 px-1 pb-1"></i>Guest
                        </a>
                      </div>
                    </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to={"/login"} state={{ data: "staff" }}>
                      <div className="headerarea__login">
                        <a href="#" className="w-100">
                          <i className="icofont-user-alt-5 px-1 pb-1"></i>Staff
                        </a>
                      </div>
                    </Link></Dropdown.Item>
                    <Dropdown.Item href="#">
                    <Link to={"/login"} state={{ data: "company" }}>
                      <div className="headerarea__login">
                        <a href="#" className="w-100">
                          <i className="icofont-user-alt-5 px-1 pb-1"></i>
                          Company
                        </a>
                      </div>
                    </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                  </>
                )}
              </div>
            </div>
          </div>
          
          <div className="container-fluid mob_menu_wrapper">
            <div className="row align-items-center">
              <div className="col-6">
                  <Link to={"/"}>
                    <div className="headerarea__left__logo">
                      <img
                        loading="lazy"
                        src="/assets/img/thelearnerlounge-01.png"
                        alt="logo"
                        width={200}
                        height={100}
                      />
                    </div>
                  </Link>

              </div>
              <div className="col-6">
                <div className="header-right-wrap">
                  <div className="headerarea__right">
                    <div className="header__cart"></div>
                  </div>
                  <div className="mobile-off-canvas">
                    <a
                      className="mobile-aside-button"
                      href="#"
                      onClick={insideNav}
                    >
                      <i className="icofont-navigation-menu"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="mobile-off-canvas-active" id="inside">
        <a className="mobile-aside-close" onClick={insideClose}>
          <i className="icofont  icofont-close-line"></i>
        </a>
        <div className="header-mobile-aside-wrap">
          <div className="mobile-search">
            <form className="search-form">
              <input type="text" placeholder="Search entire store…" />
              <button className="button-search">
                <i className="icofont icofont-search-2"></i>
              </button>
            </form>
          </div>
          <div className="mobile-menu-wrap headerarea">
            <div className="mobile-navigation">
              <nav>
                <ul className="mobile-menu">
                  <li className="menu-item-has-children">
                    <Link to={"/"} className="headerarea__has__dropdown">
                      Home
                    </Link>
                  </li>

                  <li className="menu-item-has-children ">
                    <Link to={"/"} className="headerarea__has__dropdown">
                      About
                    </Link>
                  </li>

                  <li className="menu-item-has-children " onClick={() => window.location.href = "/course"}>
                    <Link className="headerarea__has__dropdown">
                      Courses
                    </Link>
                  </li>

                  <li className="menu-item-has-children ">
                    <Link
                      to={"/constancy"}
                      className="headerarea__has__dropdown"
                    >
                      Consultancy
                    </Link>
                  </li>

                  <li className="menu-item-has-children">
                    <Link to={"/contact"} className="headerarea__has__dropdown">
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="mobile-curr-lang-wrap">
            <div className="single-mobile-curr-lang">
              <a className="mobile-language-active" href="#">
                Language <i className="icofont-thin-down"></i>
              </a>
              <div className="lang-curr-dropdown lang-dropdown-active">
                <ul>
                  <li>
                    <a href="#">English (US)</a>
                  </li>
                  <li>
                    <a href="#">English (UK)</a>
                  </li>
                  <li>
                    <a href="#">Spanish</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="single-mobile-curr-lang">
              {token ? (
                <>
                  <a className="mobile-account-active" href="#">
                    Account Logout<i className="icofont-thin-down"></i>
                  </a>
                  <div className="lang-curr-dropdown account-dropdown-active headerarea__right">
                    <Dropdown
                      style={{ position: "absolute", top: "38px", left: "40%" }}
                    >
                      <div className="dropdown-desing">
                        <Dropdown.Toggle
                          split
                          variant=""
                          id="dropdownBasicButton"
                        />
                        <i className="icofont-user-alt-5"></i>
                      </div>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          href="#"
                          onClick={() => navigate("/guesteditprofile")}
                        >
                          Edit profile
                        </Dropdown.Item>
                        <Dropdown.Item href="#" onClick={handlerSignout}>
                          Sign out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              ) : (
                <>
                  <a className="mobile-account-active" href="#">
                    Account Login<i className="icofont-thin-down"></i>
                  </a>
                  <div className="lang-curr-dropdown account-dropdown-active headerarea__right">
                    <div className="headerarea__login my-2">
                      <Link to={"/login"} state={{ data: "guest" }}>
                        <i className="icofont-user-alt-5 px-1 pb-1"></i>Guest
                      </Link>
                    </div>

                    <div className="headerarea__login my-2">
                      <Link to={"/login"} state={{ data: "staff" }}>
                        <i className="icofont-user-alt-5 px-1 pb-1"></i>Staff
                      </Link>
                    </div>

                    <div className="headerarea__login my-2">
                      <Link to={"/login"} state={{ data: "company" }}>
                        <i className="icofont-user-alt-5 px-1 pb-1"></i>Company
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="mobile-social-wrap">
            <a className="facebook" href="#">
              <i className="icofont icofont-facebook"></i>
            </a>
            <a className="twitter" href="#">
              <i className="icofont icofont-twitter"></i>
            </a>
            <a className="pinterest" href="#">
              <i className="icofont icofont-pinterest"></i>
            </a>
            <a className="instagram" href="#">
              <i className="icofont icofont-instagram"></i>
            </a>
            <a className="google" href="#">
              <i className="icofont icofont-youtube-play"></i>
            </a>
          </div>
        </div>
      </div>
      {/* <!-- header section end --> */}
    </>
  );
};

export default Header;
