/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import ShowPagination from './ShowPagination'

const CourseSection01 = () => {
  return (
    <>
     {/* <!-- breadcrumbarea__section__start --> */}

<div className="breadcrumbarea">
    <div className="container">
        <div className="row">
            <div className="col-xl-12">
                <div className="breadcrumb__content__wraper" data-aos="fade-up">
                    <div className="breadcrumb__title">
                        <h2 className="heading">Courses Page</h2>
                    </div>
                    <div className="breadcrumb__inner">
                        <ul>
                            <li><Link to={"/"}>Home</Link></li>
                            <li>Courses</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="shape__icon__2">
        <img loading="lazy"  className=" shape__icon__img shape__icon__img__1" src="assets/img/herobanner/herobanner__1.png" alt="photo"/>
        <img loading="lazy"  className=" shape__icon__img shape__icon__img__2" src="assets/img/herobanner/herobanner__2.png" alt="photo"/>
        <img loading="lazy"  className=" shape__icon__img shape__icon__img__3" src="assets/img/herobanner/herobanner__3.png" alt="photo"/>
        <img loading="lazy"  className=" shape__icon__img shape__icon__img__4" src="assets/img/herobanner/herobanner__5.png" alt="photo"/>
    </div>

</div>
{/* <!-- breadcrumbarea__section__end--> */}

{/* <!-- course__section__start   --> */}
<div className="coursearea sp_top_100 sp_bottom_100">
    <div className="container">
        <div className="row">
            <div className="col-xl-12">
                <div className="tab-content tab__content__wrapper" id="myTabContent">
                    <div className="tab-pane fade  active show" id="projects__one" role="tabpanel" aria-labelledby="projects__one">
                        <div className="row">
                        <ShowPagination />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- course__section__end   --> */}
    </>
  )
}

export default CourseSection01
