/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const HeroSection05 = () => {
  return (
    <>
      <div className="section-05 py-5" style={{ background: "#F9F9F9" }}>
        <div className="text-center mx-auto">
          <h1>What We Offer</h1>
          <p className="para">
            Our training modules are meticulously crafted by industry experts,
            ensuring you receive the most relevant and up-to-date content. We
            offer a variety of packages to suit different learning needs and
            budgets, including:
          </p>
        </div>

        <div className="container">
          <div className="row first">
            <div className="col-lg-6">
              <div className="w-75 w-sm-100 container">
                <h2>Individual Modules</h2>
                <p className="para-show">
                  Perfect for professionals seeking to upskill in specific
                  areas.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="w-75 container">
                <h2>Corporate Packages</h2>
                <p className="para-show">
                  Tailored for organizations aiming to train their teams and
                  lead successful business transformations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row second mb-5">
            <div className="col-lg-6">
              <div className="w-75 w-sm-100 container">
                <h2>Custom Solutions</h2>
                <p className="para-show">
                  For those who require a more personalized approach, we offer
                  bespoke modules crafted to meet your unique business
                  objectives.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="w-75 container">
                <h2>Our Approach</h2>
                <p className="para-show">
                  We blend theoretical knowledge with practical insights,
                  delivering an engaging and interactive learning experience.
                  Our modules are accessible online, giving you the flexibility
                  to learn at your own pace, from anywhere in the world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
      {/* <div className="section__title">
        <div className="section__title__heading ">
          <h2>Why Choose Us?</h2>
        </div>
      </div>
      <div className="about__text__5">
        <p>
          Expertise: Our courses are developed by seasoned professionals with
          real-world experience.
        </p>
        <p>
          Flexibility: Learn when it suits you, with accessible online content
          available 24/7.
        </p>
        <p>
          Practicality: Our training is not just theoretical; it's about real
          skills for real business challenges.
        </p>
        <p>
          Support: We offer ongoing support to ensure your learning journey is
          smooth and beneficial.
        </p>
        <p>
          Join us at THE LEARNER LOUNGE and be a part of the modern business
          transformation.
        </p>
        <p>
          Whether you're an individual looking to advance your career or a
          company seeking to equip your team with essential skills, we're here
          to help you succeed in today's dynamic business environment.
        </p>
      </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection05;
