/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import axiosInstance from '../../../utlilites/axios';
import Loader from '../../../Components/Guest/loader/Loader';
import PasswordEdit from '../../../Components/Admin/PasswordEdit';


const PrifileEdit = () => {
    const [Profile, setProfile] = useState(true)
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()
    const profile = JSON.parse(localStorage.getItem("user"))
    console.log(profile)

    const ProfileSchema = Yup.object().shape({
        fname: Yup.string().required('Required'),
        lname: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            fname: profile?.fname || '',
            lname: profile?.lname || '',
            email: profile?.email || '',
        },
        validationSchema: ProfileSchema,
        onSubmit: (values) => {
            setLoader(true);
            console.log(values.errors, "formErrors");
            try {
                const ProfileData = new FormData();
                ProfileData.append('fname', values?.fname);
                ProfileData.append('lname', values?.lname);
                ProfileData.append('email', values?.email);
                axiosInstance.post("profile/update", ProfileData)
                    .then((response) => {
                        if (response?.data?.status === true) {
                            console.log(response, "ProfileData")
                            setLoader(false);
                            toast.success("Update Profile SuccessFully");
                            navigate('/admin/profile')
                        }
                    }).catch((errors) => {
                        setLoader(false);
                        console.error(errors);
                        toast.error('error');
                    })

            } catch (error) {
                setLoader(false);
                toast.error('error');
                console.error(error);
            }
        },
    });



    return (
        <>
            <div class="dashboard__content__wraper">
                <div class="dashboard__section__title">
                    <h4>My Profile</h4>
                </div>
                <div class="row">
                    <div class="col-xl-12 aos-init aos-animate" data-aos="fade-up">
                        <ul class="nav  about__button__wrap dashboard__button__wrap" id="myTab"
                            role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class={`single__tab__link ${Profile ? 'active' : null}`} data-bs-toggle="tab"
                                    data-bs-target="#projects__one" type="button" aria-selected="true"
                                    role="tab" onClick={() => setProfile(true)}>Profile</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class={`single__tab__link ${!Profile ? 'active' : null}`} data-bs-toggle="tab"
                                    data-bs-target="#projects__two" type="button" aria-selected="false"
                                    role="tab" tabindex="-1" onClick={() => setProfile(false)}>Password</button>
                            </li>




                        </ul>
                    </div>


                    <div class="tab-content tab__content__wrapper aos-init aos-animate"
                        id="myTabContent" data-aos="fade-up">
                        {Profile ?
                            <div className={`tab-pane fade ${Profile ? " active show" : null}`} id="projects__one" role="tabpanel"
                                aria-labelledby="projects__one">
                                <div class="row">
                                    <div class="col-xl-12">

                                        {/* <!-- <div class="dashboardarea__wraper">
                                        <div class="dashboardarea__img dashboardarea__margin__0">
                                            <img loading="lazy" src="../img/dashbord/dashbord__1.jpg" alt="">
                                                <div class="dashboardarea__inner">
                                                    <div class="dashboardarea__left">
                                                        <div class="dashboardarea__left__img">
                                                            <img loading="lazy" src="../img/dashbord/dashbord__2.jpg"
                                                                alt="">
                                                        </div>

                                                    </div>

                                                    <div class="dashboardarea__right">
                                                        <div class="dashboardarea__right__button">
                                                            <a class="default__button" href="#">Create a New
                                                                Course
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="24" height="24"
                                                                    viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    class="feather feather-arrow-right">
                                                                    <line x1="5" y1="12" x2="19" y2="12">
                                                                    </line>
                                                                    <polyline points="12 5 19 12 12 19">
                                                                    </polyline>
                                                                </svg></a>
                                                        </div>


                                                    </div>
                                                </div>
                                        </div>
                                    </div> --> */}
                                        <form onSubmit={formik.handleSubmit}>
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <div class="dashboard__form__wraper">
                                                        <div class="dashboard__form__input">
                                                            <label >First Name</label>
                                                            <input type="text" placeholder="First Name" name='fname'
                                                                onChange={formik.handleChange}
                                                                value={formik.values.fname}
                                                            />
                                                        </div>
                                                        {formik.errors.fname && <div className="error">{formik.errors.fname}</div>}
                                                    </div>
                                                </div>

                                                <div class="col-xl-6">
                                                    <div class="dashboard__form__wraper">
                                                        <div class="dashboard__form__input">
                                                            <label >Last Name</label>
                                                            <input type="text" placeholder="Last Name" name='lname'
                                                                onChange={formik.handleChange}
                                                                value={formik.values.lname}
                                                            />
                                                        </div>
                                                        {formik.errors.lname && <div className="error">{formik.errors.lname}</div>}
                                                    </div>
                                                </div>

                                                <div class="col-xl-12">
                                                    <div class="dashboard__form__wraper">
                                                        <div class="dashboard__form__input">
                                                            <label >Email</label>
                                                            <input type="email" placeholder="Email" name='email'
                                                                onChange={formik.handleChange}
                                                                value={formik.values.email}
                                                            />
                                                        </div>
                                                        {formik.errors.email && <div className="error">{formik.errors.email}</div>}
                                                    </div>
                                                </div>

                                                <div class="col-xl-12">
                                                    <div class="dashboard__form__button">
                                                        <button class="default__button" type='submit'>
                                                            Update Info
                                                            {
                                                                loader ? <Loader /> : null
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            :

                            <div className={`tab-pane fade ${!Profile ? " active show" : null}`} id="projects__two" role="tabpanel"
                                aria-labelledby="projects__two">
                                <PasswordEdit />
                            </div>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}

export default PrifileEdit