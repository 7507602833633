import React from 'react'
import Header from './Header'
import Footer from './Footer'

const LayoutGuest = ({children}) => {
  return (
    <>
    <div className='w-100'>
    <img className='w-100' src="assets/img/Group6.png" alt="" />
    </div>
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default LayoutGuest






