import React from 'react'

const Footer = () => {
    return (
        <>
           <div className="footer-bottom">
                    <div className="container">
                        <div className="footer-wrapper d-flex flex-wrap justify-content-center align-items-center text-center">
                            <p className="copyright text-white">Copyrights &copy; 2023 All Rights Reserved by <a href="#0" className=" text-white ms-2">The Learner Lounge</a></p>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Footer