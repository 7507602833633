/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useMemo } from 'react';
import {
    MaterialReactTable,
} from 'material-react-table';
import { useDispatch, useSelector } from '../../../../Redux/store';
import { Link, useNavigate } from 'react-router-dom';
import { getCompany } from '../../../../Redux/Slices/Company';



const Comapny = () => {

    const { companies } = useSelector((state) => state.Company)
    console.log(companies, "subadmins")
    const uniqueObjectsSet = Array.from(new Set(companies.map(obj => obj.name)))
        .map(name => companies.find(obj => obj.name === name));

    console.log(uniqueObjectsSet, "uniqueObjectsSet");

    const tableData = uniqueObjectsSet || [];
    const navigate = useNavigate()

    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'id', //access nested data with dot notation
            //     header: 'S.No',
            //     size: 100,
            // },
            {
                accessorKey: 'name', //access nested data with dot notation
                header: 'Company Name',
                size: 100,
            },

            {
                accessorKey: 'logo',
                header: 'Logo',
                Cell: ({ row }) => (
                    <img src={row?.original?.logo} className='img-fluid' alt='company-logo' />
                ),
                size: 100,
            },
            {
                accessorKey: 'coures',
                header: 'Courses',
                size: 100,
                Cell: ({ row }) => (
                    <>
                        <button className='default__button' onClick={() => navigate(`/admin/course/${row?.original?.name}`)}>Courses</button>
                    </>
                )
            },
        ],
        [],
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCompany())
    }, [dispatch])


    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="dashboardarea__wraper">
                            <div class="dashboardarea__img">
                                <div class="dashboardarea__inner admin__dashboard__inner">
                                    <div class="dashboardarea__left">
                                        <div class="dashboardarea__left__img">
                                            <img loading="lazy" src="/assets/img/dashbord/dashbord__2.jpg" alt="" />
                                        </div>
                                        <div class="dashboardarea__left__content">
                                            {/* <h5>Hello</h5> */}
                                            <h4>Michle Obema</h4>
                                        </div>
                                    </div>
                                    <div class="dashboardarea__right">
                                        <div class="dashboardarea__right__button">
                                            <Link class="default__button" to="/admin/addcourse">Create Company Course
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dashboard__content__wraper">
                <div class="dashboard__section__title">
                    <h4>Company</h4>
                </div>
                <div class="row">
                    <MaterialReactTable
                        columns={columns}
                        data={tableData}
                    />
                </div>
            </div>
        </>
    )
}

export default Comapny