import React, { useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { FaArrowLeft } from "react-icons/fa";
import moment from 'moment'

export default function Calendars() {
  const [dateState, setDateState] = useState("");

  const changeDate = (date) => {
    // const select = moment(date).format('MMMM Do YYYY');
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setDateState(formattedDate);
  };

  const resetDate = [
    {
      time: '10:00 AM',
    },
    {
      time: '11:00 AM',
    },
    {
      time: '12:00 PM',
    },
    {
      time: '01:00 PM',
    },
    {
      time: '02:00 PM',
    },
    {
      time: '03:00 PM',
    },
    {
      time: '04:00 PM',
    },
    {
      time: '05:00 PM',
    },
    {
      time: '06:00 PM',
    },
  ]

  const handlerTime = () => {
    console.log(dateState);
  }

  return (
    <>
      {
        !dateState ?
          <Calendar
            value={dateState}
            onChange={changeDate}
          />
          :
          <>
            <div className='d-inline-flex'>
              <FaArrowLeft size={24} className='mt-2' style={{cursor:'pointer'}} onClick={() => setDateState("")} />
              <h1 className='text-center mx-5' style={{ fontFamily:' var(--bodyFont)'}}>Time Slot</h1>
            </div>
            <div className='d-flex justify-content-between align-items-center flex-wrap'>
              {
                resetDate?.map((item, index) => {
                  return <button key={index} className='calendar-button rounded border px-3 py-2 my-2'>{item.time}</button>

                })
              }
            </div>
            <button className='calendar-buttons border rounded w-100 py-2 mt-2' onClick={handlerTime}>Time Slot</button>
          </>
      }
    </>
  );
}


