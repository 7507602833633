/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Paginations from "./Paginations";
import "./index.css";
import { useDispatch, useSelector } from "../../../Redux/store";
import { getUserCourses } from "../../../Redux/Slices/userCourses";
import { Link } from "react-router-dom";

const ShowPagination = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const dispatch = useDispatch();
  const { usercourses } = useSelector((state) => state.usercourses);
  useEffect(() => {
    dispatch(getUserCourses());
    setBlogPosts(usercourses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);
  console.log(currentPosts);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(blogPosts.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="container">
      <div className="blog-content-section">
        <div className="blog-container">
          {currentPosts?.map((data) => (
            <Link to={`/coursedetail/${data.id}`}>
            <div className="blog-post" key={data.id}>
              <img className="cover-img" src={data.image} alt="" />
              <div className="gridarea__content">
                <div className="gridarea__list">
                  <ul>
                    <li>
                      <i className="icofont-book-alt"></i> {data.lesson.length}{" "}
                      Lesson
                    </li>
                    <div className="gridarea__heading">
                  <h3 className="mt-3">
                    {data.course_name}
                  </h3>
                </div>
                  </ul>
                </div>
              </div>
              <hr />
              <div className="card-details">
                <div className="lh-details flex justify-content-center align-items-center">
                  <img className="author-img" src={data.logo} alt="" />
                  <div className="gridarea__small__content">
                    <h6 className="mt-4">{data.name}</h6>
                  </div>
                </div>
              </div>
            </div>
            </Link>
          ))}
        </div>
        <Paginations
          postsPerPage={postsPerPage}
          totalPosts={blogPosts.length}
          currentPage={currentPage}
          paginate={paginate}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </div>
    </div>
  );
};

export default ShowPagination;
